/* ------------------------------------------------------------------------- */


/* MAPS CSS */


/* ------------------------------------------------------------------------- */

/* NEWS ITEM */

.news-item { }
.news-item a { position:relative; display:inline-block; width:100%; height:100%; color:$color-txt; overflow: hidden; background-color:white; border-left:1px solid $color; border-bottom:1px solid $color; padding:0 0 15px 20px;}
.news-item a .image-box { position:relative; height:230px; margin-bottom:20px; overflow: hidden; }
.news-item a .image-box .image { display:block; width:100%; height:100%; background-size:cover; background-position:center; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.news-item a .image-box .tags { position:absolute; top:15px; left:0; display:flex; flex-wrap:wrap;   }
.news-item a .image-box .tags span { display:inline-block; color:$color-txt; text-transform:uppercase; font-size:16px; line-height:$line-height2; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.news-item a .title { margin-bottom:0px; color:$color-txt; font-family:$main; font-size:18px; font-weight:$font-weight-semibold; line-height:$line-height; height:100px; color:$color-txt; display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.news-item a .news-arrow { transition: all .8s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .8s cubic-bezier(.07,.71,.1,.9);}
.news-item a:hover .image-box .image { transform:scale(1.1);}
.news-item a:hover .news-arrow { transform: translateX(6px); }
.news-item a:hover .image-box .tags span { background-color:$color; color:white; }

/* MEDIA ITEM */

/*.media-item { }
.media-item a { position:relative; display:inline-block; width:100%; height:100%; color:$color-txt; overflow: hidden; background-color:white;  }
.media-item a .image-box { position:relative; height:200px; margin-bottom:15px; overflow: hidden; }
.media-item a .image-box .image { display:block; width:100%; height:100%; background-size:cover; background-position:center; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item a .image-box .play { position: absolute; top: 0; left: 0; width:100%; height:100%; display:flex; align-items: center; justify-content: center; z-index:1; background-color:rgba(0,0,0,0.2);}
.media-item a .tags { margin:0 0 10px; display:flex; flex-wrap:wrap; }
.media-item a .tags span { display:inline-block; color:$color-txt; font-size:16px; line-height:$line-height2; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item a .title { margin-bottom:0px; color:$color-txt; font-family:$main; font-size:18px; font-weight:$font-weight-semibold; line-height:$line-height; height:80px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.media-item a:hover .image-box .image { transform:scale(1.1);}
.media-item a:hover .tags span { color:white; background-color:$color; }*/

.media-item { color:$color-txt; overflow: hidden; }
.media-item .image-box { position:relative; height:200px; margin-bottom:15px; overflow: hidden; }
.media-item .image-box .image { display:block; width:100%; height:100%; background-size:cover; background-position:center; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item .image-box .play { position: absolute; top: 0; left: 0; width:100%; height:100%; display:flex; align-items: center; justify-content: center; z-index:1; background-color:rgba(0,0,0,0.2);}
.media-item .tags { margin:0 0 10px; display:flex; flex-wrap:wrap; }
.media-item .tags span { display:inline-block; color:$color-txt; font-size:16px; line-height:$line-height2; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item .title { margin-bottom:0px; color:$color-txt; font-family:$main; font-size:18px; font-weight:$font-weight-semibold; line-height:$line-height; height:80px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.media-item:hover .image-box .image { transform:scale(1.1);}
.media-item:hover .tags span { color:white; background-color:$color; }
.media-open { width: 100%;height: 100%;position: absolute;top: 0;left: 0; cursor: pointer; z-index: 1;}

/* FILE ITEM */

.file-item { min-height:190px; background-color:white; border-left:1px solid $color; border-bottom:1px solid $color; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.file-item a { position:relative; display:flex; flex-direction: column; justify-content: space-between; width:100%; height:100%; color:$color-txt; overflow: hidden; padding:0 0 20px 20px;}
.file-item a .file-content { display:flex; justify-content: space-between; align-items: flex-start;}
.file-item a .file-content .tags { margin:0 0 5px; display:flex; flex-wrap:wrap;   }
.file-item a .file-content .tags span { display:inline-block; text-transform:uppercase; font-size:16px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.file-item a .file-content .file-arrow { padding:0px 10px; }
.file-item a .file-content .file-arrow .svg { transition: all .8s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .8s cubic-bezier(.07,.71,.1,.9); }
.file-item a:hover .file-content .file-arrow .svg { transform:translateY(6px); }
.file-item a .title { margin-bottom:5px; font-size:20px; line-height:110%; max-height:110px; color:$color-txt; display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.file-item a:hover .title { color:$color;}
.file-item a:hover .file-content .tags span { background-color:$color; color:white; }



/* NEWS & MEDIA ARCHIVE */

.header-news { display:flex; align-items: flex-start; justify-content: space-between; }
.header-news .page-title { margin-bottom:0; }
.header-news .header-news-right { display:flex; flex-wrap: wrap; justify-content: flex-end; }
/*.header-news .header-news-right .header-news-filter .filter-group button { padding: 8px 12px; border-radius:$border-radius; line-height:$line-height2; display: inline-block; margin-left: 5px; background-color:$color-bg; cursor: pointer;  }
.header-news .header-news-right .header-news-filter .filter-group button.active {background-color:#d2d2d2;}
.header-news .header-news-right .header-news-order select { width:auto; margin-left: 10px; padding:7px 40px 6px 10px; }*/
.header-news .header-news-right .header-news-search { margin-left: 10px; }

.container-news { display:flex; flex-wrap:wrap; }
.container-news .news-item { width: 30%; margin:0 3% 4% 0; }
.container-news .media-item { width: 22%; margin:0 3% 3% 0; }
.container-news .file-item { width: 22%; margin:0 3% 5% 0; height: 190px; }


/*  NEWS SINGLE  */

.single-news-header { display:flex; background-color:$color-bg; }
.single-news-header .single-news-header__left { flex-basis:50%;  }
.single-news-header .single-news-header__left .tags { margin:0 0 15px; display:flex; flex-wrap:wrap;   }
.single-news-header .single-news-header__left .tags span { display:inline-block; text-transform:uppercase; font-size:16px; line-height:$line-height2; border-radius:$border-radius; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; }
.single-news-header .single-news-header__left .tags span.link { background-color:$color; color:white; }
.single-news-header .single-news-header__left .tags span.link a { color:white; }
.single-news-header .single-news-header__left .tags span.date { background-color:white; color:$color-txt; }
.single-news-header .single-news-header__left .title { margin-bottom:20px; }
.single-news-header .single-news-header__left p { line-height:$line-height3; }
.single-news-header .single-news-header__right { flex-basis:50%; background-size:cover; background-position:center; min-height: 450px;}


.single-news-content p { line-height: $line-height3; }
.single-news-content .tabla { margin: 40px 0;}
.single-news-content ul { padding-left: 25px; margin: 30px 0; }
.single-news-content ul li { list-style: disc; margin-bottom: 20px; line-height:$line-height3;}
.single-news-content h1,
.single-news-content h2,
.single-news-content h3,
.single-news-content h4 { margin:40px 0 30px; }
.single-news-content h1:first-child,
.single-news-content h2:first-child,
.single-news-content h3:first-child,
.single-news-content h4:first-child { margin:0 0 30px; }

.single-news-content img { margin:30px 0; width:100%; } 
.single-news-content .wp-caption { max-width: 100%; background-color: white; border-radius: 10px; margin-top: 50px; margin-bottom: 50px; padding: 0; overflow: hidden;}
.single-news-content .wp-caption p.wp-caption-text {  text-align: left; line-height:120%; font-size:16px; padding:10px 20px; opacity:0.4; }

.single-news-content blockquote { margin: 50px 0; padding-left: 20px;  border-left: 1px solid $color; }



/*  MEDIA SINGLE POPUP */



.media-info-section-background { display: none; left: 0; top: 0; width: 100%; height: 100%; position: fixed; z-index: 1000; background-color: rgba(0,0,0,.7);}
.media-info-section-container { position: relative; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }
.media-info-container {  position: relative; background-color: #fff; margin: auto; width: 90%; height: 620px; max-height:90%; max-width: 1000px; z-index: 1; overflow: auto; }

.close{display: flex;align-items: center;justify-content: center;width: 60px;height: 60px;border-radius: 100px;  position: absolute;  top: 0; right:0;  cursor: pointer; }
.close span {width: 22px; height: 22px; background: url(../images/close.svg) 50%/cover; display: block;}
.close .svg path { fill:white; }

.media-nav { position:absolute; top:45%; left:0; width:100%; display:flex; justify-content: space-between;}
.media-nav .nav-prev, .media-nav .nav-next { cursor: pointer; display:inline-block; background-color:$color; padding:10px 15px;}
.media-nav .nav-prev .svg path, .media-nav .nav-next .svg path { fill:white;}
.media-nav .nav-prev .svg { transform:rotate(180deg);}

.media-close-section { position: absolute; width: 100%; height: 100%; left: 0; top: 0;}

.media-content { padding:50px 9% 0; overflow: auto; height: 100%;}
.media-box.hide { display:none; }
.media-box .name { font-size:22px; font-weight:$font-weight-semibold; line-height:$line-height2; text-transform:uppercase; margin-bottom:10px;}


.video-responsive { height: 0; overflow: hidden; padding-bottom: 56.25%; padding-top: 30px; position: relative; }
.video-responsive iframe, .video-responsive object, .video-responsive embed { height: 100%; left: 0; position: absolute; top: 0; width: 100%; }



@media only screen and (max-width: 1300px){
	/* items */
	
    .file-item { min-height:160px; }
    .file-item a .title,
    .media-item a .title,
    .news-item a .title { font-size: 18px;}
}
@media only screen and (max-width: 1100px){
	/*.header-news .header-news-right .header-news-filter .filter-group button,
	.header-news .header-news-right .header-news-order select,*/
	.header-news-search input[type=search] { font-size:13px; }
    .news-item a .image-box { height:200px;}
    /*.container-news .file-item,
    .container-news .news-item { width: 25%; }*/
}
@media only screen and (max-width: 1000px){
	.header-news { flex-direction: column;}
	.header-news .header-news-right { margin-top: 20px; justify-content: flex-start;}
	/*.header-news .header-news-right .header-news-filter .filter-group button { margin-left:0; margin-right:5px; margin-bottom: 5px; }
	.header-news .header-news-right .header-news-order select { margin-left:0; margin-right: 10px;}*/
	.header-news .header-news-right .header-news-search { margin-left: 0; margin-right: 10px; }
}
@media only screen and (max-width: 900px){
	.header-news-filter { width: 100%; margin-bottom: 10px;}
    .container-news .media-item,
    .container-news .file-item { width: 30%;  }
    .file-item a .title,
    .news-item a .title,
    .media-item a .title { font-size: 16px;}
    .news-item a .title {height:90px;}
    .media-item a .title {height:67px;}
    .single-news-header { flex-direction:column; }
    .single-news-header .single-news-header__right { flex-basis: unset; }

}
@media only screen and (max-width: 700px){
    .container-news .file-item { width: 48%; margin:0 2% 3% 0; min-height:150px; height: 150px; }
    .file-item { min-height:150px; }
    .container-news .media-item,
    .container-news .news-item { width: 48%; margin:0 2% 3% 0; min-height:unset; height: auto; }
    .single-news-header .single-news-header__right { min-height: 300px; }
}
@media only screen and (max-width: 480px){
    .container-news .file-item { width: 100%; margin:0 0 25px; min-height:unset; height: auto; }
    .file-item { min-height:unset; }
    .container-news .media-item,
    .container-news .news-item { width: 100%; margin:0 0 25px;}


    .header-news .header-news-right { width:100%;}
    .header-news .header-news-right .header-news-search { width:100%; margin-right:0;}
    .header-news-order.sort-options { width: 100%; margin-bottom: 10px;}
    .header-news .header-news-right .header-news-order select { width:100%; margin-right:0; }

    .file-item a .title,
    .news-item a .title,
    .media-item a .title { font-size: 15px;}
    .file-item a .title { max-height:unset; -webkit-line-clamp: unset;  text-overflow: unset;}
    .news-item a .title {height:auto; -webkit-line-clamp: unset;  text-overflow: unset;}
    .media-item a .title {height:auto; -webkit-line-clamp: unset;  text-overflow: unset;}
}
