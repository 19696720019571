/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
/*.owl-carousel{
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}*/
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: #FFF;
  text-decoration: none;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/*
 *  Owl Carousel - Animate Plugin
 */
/*.owl-carousel{
	.animated {
		animation-duration: 1000ms;
		animation-fill-mode: both;
	}
	.owl-animated-in {
		z-index: 0;
	}
	.owl-animated-out {
		z-index: 1;
	}
	.fadeOut {
		animation-name: fadeOut;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}*/
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
/* ------------------------------------------------------------------------- */
/* FONTS */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* VARIABLES */
/* ------------------------------------------------------------------------- */
/* 400 y 500*/
/* 600 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}

/* ==========================================================================
   RESET CSS
   ========================================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
figure,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  text-decoration: none;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}

a {
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

p a {
  outline: 0;
}

p a:hover {
  text-decoration: underline;
}

input[type=submit],
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

html,
body {
  overflow-x: hidden;
}

/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE - Classes used throught all the project */
/* ------------------------------------------------------------------------- */
body {
  background-color: white;
  min-height: 100%;
  font-family: "Barlow", sans-serif;
  color: #000000;
  font-size: 18px;
  line-height: 140%;
  -webkit-font-smoothing: antialiased;
}

p {
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 20px;
}

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-medio {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-mini {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

.container-micro {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
}

img {
  max-width: 100%;
  height: auto;
}

a,
button {
  cursor: pointer;
}

.clearfix, .clear {
  clear: both;
}

strong {
  font-weight: 500;
}

p a, a {
  text-decoration: none;
  color: #0B0B80;
  font-weight: 400;
}

p i, i {
  font-style: italic;
}

ul {
  list-style: none;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

hr {
  border: none;
  margin: 25px 0;
  width: 50px;
  height: 1px;
  background: #0B0B80;
}

hr.center {
  margin: 25px auto;
}

.no_padding {
  padding: 0px !important;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.padding-small {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-medium {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-big {
  padding-top: 140px;
  padding-bottom: 140px;
}

.padding-anchor {
  padding-top: 100px;
  padding-bottom: 40px;
}

.left {
  float: left;
}

.right {
  float: right;
}

/* CALLTOACTIONS */
a.calltoaction {
  display: inline-block;
  margin: 0;
  width: auto;
  text-align: center;
  padding: 10px 20px;
  color: white;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  line-height: 120%;
  border-radius: 0px;
  text-transform: uppercase;
  font-size: 14px;
  background-color: #0B0B80;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  opacity: 1;
  -webkit-appearance: none;
}

a.calltoaction:hover {
  text-decoration: none;
  background-color: #000000;
}

a.calltoaction.white {
  background: white;
  color: #000000;
}

a.calltoaction.white:hover {
  text-decoration: none;
}

/* ANIMATIONS */
.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1.6s;
  -moz-animation-duration: 1.6s;
  -ms-animation-duration: 1.6s;
  -o-animation-duration: 1.6s;
  animation-duration: 1.6s;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.fadeToLeft {
  -webkit-animation-name: fadeToLeft;
  -moz-animation-name: fadeToLeft;
  -o-animation-name: fadeToLeft;
  animation-name: fadeToLeft;
}

.fadeToRight {
  -webkit-animation-name: fadeToRight;
  -moz-animation-name: fadeToRight;
  -o-animation-name: fadeToRight;
  animation-name: fadeToRight;
}

.fadeOpacity {
  -webkit-animation-name: fadeOpacity;
  -moz-animation-name: fadeOpacity;
  -o-animation-name: fadeOpacity;
  animation-name: fadeOpacity;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes blinkingimage {
  50% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes blinking {
  50% {
    opacity: 1;
  }
}
@keyframes blinkingshadow {
  50% {
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate(0, 200px);
    transform: translate(0, 200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeToLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate(10px, 0);
    transform: translate(100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeToRight {
  0% {
    opacity: 0;
    -webkit-transform: translate(-10px, 0);
    transform: translate(-100px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes fadeOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@media only screen and (max-width: 1200px) {
  body, p, ul li {
    font-size: 16px;
  }
}
@media only screen and (max-width: 800px) {
  body, p, ul li {
    font-size: 15px;
  }

  .padding-small {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .padding-medium {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .padding-big {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .padding-anchor {
    padding-top: 60px;
    padding-bottom: 40px;
  }

  .columna2, .columna2-last {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .columna4 {
    width: 49%;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 600px) {
  p, ul li {
    font-size: 14px;
  }

  .padding-small {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .padding-medium {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .padding-big {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .padding-anchor {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/* ------------------------------------------------------------------------- */
/* GENEREAL STYLE - Classes used throught all the project */
/* ------------------------------------------------------------------------- */
h1, h2, h3, h4, h5 {
  font-family: "Space Grotesk", serif;
  font-weight: 600;
  color: #000000;
  line-height: 120%;
  margin-bottom: 30px;
}

h1 {
  font-size: 35px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 16px;
}

.title_center {
  text-align: center;
}

.title_white {
  color: white;
}

@media only screen and (max-width: 1100px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 800px) {
  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 25px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 16px;
  }

  h1, h2, h3, h4, h5 {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 480px) {
  h1, h2, h3, h4, h5 {
    margin-bottom: 20px;
  }
}
/* ------------------------------------------------------------------------- */
/* COOKIES */
/* ------------------------------------------------------------------------- */
.cookies {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 90%;
  max-width: 400px;
  padding: 25px 40px 20px 25px;
  z-index: 100;
  background-color: white;
  border-radius: 0px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.cookies .message-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 15px;
  height: 15px;
  background: transparent url(../images/close.svg) no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.cookies p {
  font-size: 14px;
  color: #000000;
  line-height: 130%;
  margin-bottom: 0;
}

.cookies p.cookies__text {
  margin-bottom: 3px;
}

.cookies p:last-child {
  text-align: right;
}

.cookies p a {
  color: #000000;
  font-weight: 500;
  font-size: 15px;
}

.cookies p a:hover {
  color: #000000;
}

@media only screen and (max-width: 800px) {
  .cookies p {
    font-size: 13px;
  }

  .cookies p a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .cookies {
    width: calc(100% - 20px);
    max-width: none;
  }

  .cookies p {
    font-size: 12px;
  }

  .cookies p a {
    font-size: 13px;
  }
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: #4a4a4a;
}

/* ------------------------------------------------------------------------- */
/* HEADER STYLES */
/* ------------------------------------------------------------------------- */
.header {
  width: 100%;
  position: fixed;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.header.fixed {
  position: fixed;
  padding: 5px 0;
  z-index: 10;
}

.header.menu-opened {
  position: fixed;
}

.header .container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5%;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.header.fixed .container-header {
  padding: 10px 5%;
}

.header .header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header .web-logo {
  /*z-index: 11;*/
}

.header .web-logo .header_logo {
  display: block;
  width: 210px;
}

.header.fixed .web-logo .header_logo {
  width: 150px;
  height: 50px;
}

/* MENUS */
.header__nav ul {
  display: flex;
}

.header__nav ul li {
  padding: 0 10px;
  position: relative;
  margin-left: 3px;
}

.header__nav ul li a {
  color: #000000;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 120%;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 5px 0 5px;
}

.header__nav ul li a:after {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateZ(0);
  will-change: transform;
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #0B0B80;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  transform: translate3d(-103%, 0, 0);
  -webkit-transform: translate3d(-103%, 0, 0);
}

.header__nav ul li a:hover {
  color: #0B0B80;
}

.header__nav ul li a:hover:after,
.header__nav ul li.selected a:after {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.header__nav ul li.contact {
  background-color: #0B0B80;
  margin-left: 10px;
}

.header__nav ul li.contact a {
  color: white;
}

.header__nav ul ul.sub-menu {
  visibility: hidden;
  opacity: 0;
  padding: 15px 25px;
  border-radius: 0px;
  margin-left: -25px;
  position: absolute;
  background: white;
  width: auto;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.header__nav ul li:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
}

.header__nav ul ul.sub-menu li {
  text-align: left;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.header__nav ul ul.sub-menu li a {
  display: inline-block;
  font-size: 14px;
  line-height: 140%;
  padding: 5px 0;
  overflow: hidden;
  color: #000000;
}

.header__nav ul ul.sub-menu li a:after {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateZ(0);
  will-change: transform;
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #000000;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  transform: translate3d(-103%, 0%, 0);
  -webkit-transform: translate3d(-103%, 0%, 0);
}

.header__nav ul ul.sub-menu li.current_page_item a:after,
.header__nav ul ul.sub-menu li a:hover:after {
  transform: translate3d(0%, 0%, 0);
  -webkit-transform: translate3d(0%, 0%, 0);
}

.header .header_social {
  margin-left: 15px;
}

.header .header_social ul {
  display: flex;
  align-items: center;
}

.header .header_social ul li {
  display: inline-block;
  margin: 0 5px;
}

.header .header_social ul li a {
  position: relative;
  overflow: hidden;
  display: flex;
}

.header .header_idiomas {
  margin-left: 10px;
}

.header .header_idiomas ul {
  display: flex;
}

.header .header_idiomas ul li {
  display: inline-block;
  margin: 0 5px;
}

.header .header_idiomas ul li a {
  color: #0B0B80;
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  display: block;
  padding: 8px 0 5px;
  text-transform: uppercase;
  opacity: 0.3;
}

.header .header_idiomas ul li a.active,
.header .header_idiomas ul li a:hover,
.header .header_idiomas ul li.selected a {
  opacity: 1;
}

#nav-open-close {
  display: none;
  margin-left: 30px;
  opacity: 1;
  z-index: 11;
  position: relative;
  width: 30px;
  height: 23px;
  cursor: pointer;
}

#nav-open-close span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #0B0B80;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
  -moz-transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
  -o-transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
  transition: 1s cubic-bezier(0.77, 0, 0.18, 1);
  /*transition: -webkit-transform 1.4s cubic-bezier(.77,0,.18,1); transition: transform 1.4s cubic-bezier(.77,0,.18,1); transition: transform 1.4s cubic-bezier(.77,0,.18,1),-webkit-transform 1.4s cubic-bezier(.77,0,.18,1);*/
}

#nav-open-close span:nth-child(1) {
  top: 0px;
}

#nav-open-close span:nth-child(2) {
  top: 11px;
}

#nav-open-close span:nth-child(3) {
  top: 22px;
}

#nav-open-close.open span {
  background: white;
}

#nav-open-close.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  left: 0px;
}

#nav-open-close.open span:nth-child(2) {
  opacity: 0;
  left: 0px;
}

#nav-open-close.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  left: 0px;
}

.header .menu-desplegable {
  position: fixed;
  top: 0;
  left: 100%;
  width: 400px;
  height: 100%;
  overflow: hidden;
  transform: translateX(100%);
  background: #0B0B80;
  padding: 40px 10px 20px 20px;
  z-index: 10;
  animation-fill-mode: both;
  transition: -webkit-transform 1.1s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.18, 1);
  transition: transform 1s cubic-bezier(0.77, 0, 0.18, 1), -webkit-transform 1s cubic-bezier(0.77, 0, 0.18, 1);
}

.header .menu-desplegable.open {
  transform: translateX(-400px);
}

/*
.menu__logo { width:100px; height:100px; margin-bottom:15px;}
.menu__logo path,
.menu__logo polygon { fill:white; }*/
.header .menu-desplegable__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .menu-desplegable .header__nav-mobile {
  position: relative;
}

.header .menu-desplegable .header__nav-mobile ul {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header .menu-desplegable .header__nav-mobile ul li {
  margin: 0 0 5px;
  display: inline-block;
  width: auto;
  text-align: center;
}

.header .menu-desplegable .header__nav-mobile ul ul {
  margin: 5px 0 10px;
}

.header .menu-desplegable .header__nav-mobile ul ul li a {
  font-size: 16px;
}

.header .menu-desplegable .header__nav-mobile ul li a {
  font-family: "Barlow", sans-serif;
  color: white;
  font-size: 25px;
  line-height: 120%;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  display: inline-block;
  padding: 0 0 2px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.header .menu-desplegable .header__nav-mobile ul li a:after {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateZ(0);
  will-change: transform;
  content: "";
  width: 0%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: white;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.header .menu-desplegable .header__nav-mobile ul li.current_page_item a:after,
.header .menu-desplegable .header__nav-mobile ul li.project-selected a:after,
.header .menu-desplegable .header__nav-mobile ul li a:hover:after {
  width: 100%;
}

main {
  margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
  .header__nav ul li a {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1100px) {
  .header .web-logo .header_logo {
    width: 150px;
    height: 50px;
  }

  .header.fixed .web-logo .header_logo {
    width: 150px;
    height: 50px;
  }

  .header__nav ul li {
    padding: 0 6px;
  }

  .header__nav ul li a {
    font-size: 13px;
  }

  main {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 1000px) {
  #nav-open-close {
    display: block;
  }

  .header__nav {
    display: none;
  }

  .header .header_social {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  }

  .header .menu-desplegable .header_idiomas {
    display: block;
  }

  .header .menu-desplegable {
    width: 100%;
  }

  .header .menu-desplegable.open {
    transform: translateX(-100%);
  }

  .header .web-logo .header_logo {
    width: 130px;
    height: 45px;
  }

  .header.fixed .web-logo .header_logo {
    width: 130px;
    height: 45px;
  }

  main {
    margin-top: 70px;
  }
}
@media only screen and (max-width: 600px) {
  .header .container-header {
    padding: 10px 5%;
  }

  .header.fixed .container-header {
    padding: 10px 5%;
  }

  .header .web-logo .header_logo {
    width: 120px;
    height: 40px;
  }

  .header.fixed .web-logo .header_logo {
    width: 120px;
    height: 40px;
  }

  main {
    margin-top: 55px;
  }
}
@media only screen and (max-width: 480px) {
  #nav-open-close {
    margin-left: 20px;
  }

  .header .header_idiomas {
    padding-left: 0;
    border-left: none;
  }
}
/* ------------------------------------------------------------------------- */
/* HOME CSS */
/* ------------------------------------------------------------------------- */
.highlighted span {
  display: inline-block;
  color: white;
  background: linear-gradient(to bottom, #F8A251 0%, #F8A251 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 5px 80%;
  text-decoration: none;
}

.home-section {
  position: relative;
  height: 750px;
  overflow: hidden;
}

.home-section .home-title {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%);
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.home-section .home-title h1 {
  color: white;
  font-size: 40px;
  line-height: 120%;
}

.home-section .home-title h1 span:nth-child(3) {
  background: linear-gradient(to bottom, #036648 0%, #036648 100%);
  background-position: 0 100%;
  background-size: 5px 80%;
  background-repeat: repeat-x;
}

.home-section .home-title h1 span:nth-child(5) {
  background: linear-gradient(to bottom, #C1002B 0%, #C1002B 100%);
  background-position: 0 100%;
  background-size: 5px 80%;
  background-repeat: repeat-x;
}

.home-section .home-title h1 span:nth-child(7) {
  background: linear-gradient(to bottom, #0B0B80 0%, #0B0B80 100%);
  background-position: 0 100%;
  background-size: 5px 80%;
  background-repeat: repeat-x;
}

/*.home-section .home-video { position:relative; width:100%; height:100%; }
.home-section .home-video video { position: relative; min-width: 100%; min-height: 100%; width: 100%; height: auto; z-index: 0;  background: url(../images/video-poster.jpg) no-repeat; background-size: cover; transition: 1s opacity;}*/
.home-section .home-video #videobt {
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  cursor: pointer;
}

.home-section .home-video .play-home {
  display: none;
  position: absolute;
  z-index: 2;
  width: 60px;
  height: 60px;
  top: 50%;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.3) url(../images/play.svg) no-repeat;
  background-size: 100%;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.home-section .home-video .play-home:hover {
  background: rgba(0, 0, 0, 0.5) url(../images/play.svg) no-repeat;
  background-size: 100%;
}

.home-section .home-video-mobile {
  display: none;
}

.home-section .home-video,
.home-section .home-video-mobile {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.home-section .home-video video,
.home-section .home-video-mobile video {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  z-index: 0;
  /* transform: translateX(-50%) translateY(-50%); 
  background: url(../img/imagen-portada.jpg) no-repeat;
  background-size: cover;*/
  transition: 1s opacity;
}

/*.home-section .owl-carousel-home { position:relative; width:100%; height:100%; }
.slider-item { position:relative; display:flex; align-items:center; padding:30px 5%; height: 750px; overflow: hidden;}
.slider-item .image { position: absolute; top: 0; left: 0; width: 100%; height: 100%;background-size: cover; background-position: center; animation: blinkingimage 30s infinite; -webkit-animation: blinkingimage 30s infinite; transform: scale(1.2); }
*/
.sound-box-content {
  z-index: 2;
  position: absolute;
  right: 20px;
  bottom: 50px;
  width: 35px;
}

.sound-box-content .home-audio {
  opacity: 0;
  visibility: hidden;
}

.sound-box-content .sound-box-button {
  display: flex;
  align-items: center;
}

.sound-box-content .sound-box-button .sound-button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  border-radius: 100px;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.sound-box-content .sound-box-button .sound-button img {
  width: 16px;
}

.sound-box-content .sound-box-button .sound-button .pause {
  position: absolute;
  width: 4px;
  height: 22px;
  transform: rotate(45deg);
}

.sound-box-content .sound-box-button .sound-button .pause:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 100%;
  border-radius: 5px;
  background: white;
}

.sound-box-content .sound-box-button .sound-button .pause:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.sound-box-content .sound-box-button .sound-button.playing .pause {
  display: none;
}

.home-links {
  display: flex;
  justify-content: space-between;
}

.home-links .home-link {
  flex-basis: 22%;
  border-left: 1px solid #0B0B80;
  border-bottom: 1px solid #0B0B80;
}

.home-links .home-link a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 25px;
  background-color: white;
}

.home-links .home-link a h2 {
  margin-bottom: 10px;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.home-links .home-link a p {
  font-size: 16px;
  color: #000000;
}

.home-links .home-link a .home-link-arrow {
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.home-links .home-link a:hover .home-link-arrow {
  transform: translateX(10px);
}

.home-links .home-link a:hover h2 {
  color: #0B0B80;
}

.home-parallax {
  position: relative;
  background-size: cover;
  background-position: center;
  display: flex;
  overflow: hidden;
}

.home-parallax:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 50%);
}

.home-parallax .container {
  z-index: 1;
  display: flex;
  justify-content: flex-end;
  padding-top: 200px;
  padding-bottom: 60px;
}

.home-parallax a.link-parallax {
  display: inline-block;
  max-width: 600px;
}

.home-parallax a.link-parallax h1 {
  color: white;
}

.home-parallax a.link-parallax p {
  color: white;
  margin-bottom: 10px;
}

.home-parallax a.link-parallax .home-link-arrow {
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.home-parallax a.link-parallax:hover .home-link-arrow {
  transform: translateX(10px);
}

.home-parallax a.link-parallax .svg path {
  fill: white;
}

.home-parallax video {
  position: absolute;
  /*top: 50%; left: 50%;*/
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: auto;
  /*transform: translateX(-50%) translateY(-50%);*/
  background: url(../images/video-poster.jpg) no-repeat;
  background-size: cover;
  transition: 1s opacity;
}

.home-parallax .opacity {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 0;
}

.title-home-news {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.title-home-news h1 {
  margin-bottom: 0;
  margin-right: 15px;
}

.title-home-news a {
  font-size: 16px;
  border-bottom: 1px solid #0B0B80;
}

.home-section-news {
  display: flex;
  margin-bottom: 40px;
}

.home-section-news .news-item {
  flex-basis: 30%;
  margin-right: 5%;
}

.home-section-news .news-item:last-child {
  margin-right: 0;
}

.home-section-news .news-item a .title {
  height: auto;
  -webkit-line-clamp: unset;
  text-overflow: unset;
}

/* CARRUSEL CSS */
.owl-theme .owl-dots,
.owl-theme .owl-nav.disabled + .owl-dots {
  position: absolute;
  bottom: 20px;
  right: 30px;
}

.owl-theme .owl-dots .owl-dot {
  background: transparent;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #0B0B80;
}

/* POPUP HOME */
.popup {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-full-close {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

/* .close -> page.scss - linea 33 */
.popup-info-container {
  margin: auto;
  width: 90%;
  max-height: 90%;
  max-width: 1000px;
  z-index: 1;
  display: flex;
}

.popup-info-container a {
  display: inline-block;
  text-align: center;
}

.popup-info-container a img {
  display: inline-block;
  max-height: 100%;
  width: auto;
}

@media only screen and (max-width: 1440px) {
  .home-section, .slider-item {
    height: 650px;
  }
}
@media only screen and (max-width: 1300px) {
  .home-links .home-link {
    flex-basis: 23%;
  }

  .home-links .home-link a {
    padding-right: 10px;
  }
}
@media only screen and (max-width: 1100px) {
  .home-section {
    height: 550px;
  }

  .home-links .home-link a p {
    font-size: 15px;
  }
}
@media only screen and (max-width: 900px) {
  .home-section, .slider-item {
    height: 500px;
  }

  .home-section .home-title h1 {
    font-size: 35px;
  }

  .home-links {
    flex-wrap: wrap;
  }

  .home-links .home-link {
    flex-basis: 48%;
    margin-bottom: 25px;
  }

  .home-section-news {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .home-section-news .news-item {
    flex-basis: 48%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .home-section, .slider-item {
    height: 450px;
  }
}
@media only screen and (max-width: 600px) {
  .home-section .home-title h1 {
    font-size: 28px;
  }

  .home-links .home-link a p {
    font-size: 14px;
  }

  .home-parallax video {
    display: none;
  }

  .home-section .home-video {
    display: none;
  }

  .home-section .home-video-mobile {
    display: block;
  }

  .sound-box-content {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .home-section .home-title h1 {
    font-size: 24px;
  }

  .home-links .home-link {
    flex-basis: 100%;
  }

  .home-links .home-link a {
    padding: 5px 5px 5px 15px;
  }

  .home-section-news .news-item {
    flex-basis: 100%;
  }

  .title-home-news a {
    font-size: 13px;
  }

  .home-parallax a.link-parallax h1 {
    font-size: 20px;
  }
}
/* ------------------------------------------------------------------------- */
/* MAPS CSS */
/* ------------------------------------------------------------------------- */
/* NEWS ITEM */
.news-item a {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  color: #000000;
  overflow: hidden;
  background-color: white;
  border-left: 1px solid #0B0B80;
  border-bottom: 1px solid #0B0B80;
  padding: 0 0 15px 20px;
}

.news-item a .image-box {
  position: relative;
  height: 230px;
  margin-bottom: 20px;
  overflow: hidden;
}

.news-item a .image-box .image {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.news-item a .image-box .tags {
  position: absolute;
  top: 15px;
  left: 0;
  display: flex;
  flex-wrap: wrap;
}

.news-item a .image-box .tags span {
  display: inline-block;
  color: #000000;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 120%;
  background-color: #f5f5f5;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.news-item a .title {
  margin-bottom: 0px;
  color: #000000;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  height: 100px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-item a .news-arrow {
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.news-item a:hover .image-box .image {
  transform: scale(1.1);
}

.news-item a:hover .news-arrow {
  transform: translateX(6px);
}

.news-item a:hover .image-box .tags span {
  background-color: #0B0B80;
  color: white;
}

/* MEDIA ITEM */
/*.media-item { }
.media-item a { position:relative; display:inline-block; width:100%; height:100%; color:$color-txt; overflow: hidden; background-color:white;  }
.media-item a .image-box { position:relative; height:200px; margin-bottom:15px; overflow: hidden; }
.media-item a .image-box .image { display:block; width:100%; height:100%; background-size:cover; background-position:center; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item a .image-box .play { position: absolute; top: 0; left: 0; width:100%; height:100%; display:flex; align-items: center; justify-content: center; z-index:1; background-color:rgba(0,0,0,0.2);}
.media-item a .tags { margin:0 0 10px; display:flex; flex-wrap:wrap; }
.media-item a .tags span { display:inline-block; color:$color-txt; font-size:16px; line-height:$line-height2; background-color:$color-bg; padding:5px 10px; margin:0 5px 5px 0; transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.media-item a .title { margin-bottom:0px; color:$color-txt; font-family:$main; font-size:18px; font-weight:$font-weight-semibold; line-height:$line-height; height:80px; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; text-overflow: ellipsis;}
.media-item a:hover .image-box .image { transform:scale(1.1);}
.media-item a:hover .tags span { color:white; background-color:$color; }*/
.media-item {
  color: #000000;
  overflow: hidden;
}

.media-item .image-box {
  position: relative;
  height: 200px;
  margin-bottom: 15px;
  overflow: hidden;
}

.media-item .image-box .image {
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.media-item .image-box .play {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.2);
}

.media-item .tags {
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
}

.media-item .tags span {
  display: inline-block;
  color: #000000;
  font-size: 16px;
  line-height: 120%;
  background-color: #f5f5f5;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.media-item .title {
  margin-bottom: 0px;
  color: #000000;
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 140%;
  height: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-item:hover .image-box .image {
  transform: scale(1.1);
}

.media-item:hover .tags span {
  color: white;
  background-color: #0B0B80;
}

.media-open {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

/* FILE ITEM */
.file-item {
  min-height: 190px;
  background-color: white;
  border-left: 1px solid #0B0B80;
  border-bottom: 1px solid #0B0B80;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.file-item a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  color: #000000;
  overflow: hidden;
  padding: 0 0 20px 20px;
}

.file-item a .file-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.file-item a .file-content .tags {
  margin: 0 0 5px;
  display: flex;
  flex-wrap: wrap;
}

.file-item a .file-content .tags span {
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 120%;
  border-radius: 0px;
  background-color: #f5f5f5;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.file-item a .file-content .file-arrow {
  padding: 0px 10px;
}

.file-item a .file-content .file-arrow .svg {
  transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.8s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.file-item a:hover .file-content .file-arrow .svg {
  transform: translateY(6px);
}

.file-item a .title {
  margin-bottom: 5px;
  font-size: 20px;
  line-height: 110%;
  max-height: 110px;
  color: #000000;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-item a:hover .title {
  color: #0B0B80;
}

.file-item a:hover .file-content .tags span {
  background-color: #0B0B80;
  color: white;
}

/* NEWS & MEDIA ARCHIVE */
.header-news {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.header-news .page-title {
  margin-bottom: 0;
}

.header-news .header-news-right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

/*.header-news .header-news-right .header-news-filter .filter-group button { padding: 8px 12px; border-radius:$border-radius; line-height:$line-height2; display: inline-block; margin-left: 5px; background-color:$color-bg; cursor: pointer;  }
.header-news .header-news-right .header-news-filter .filter-group button.active {background-color:#d2d2d2;}
.header-news .header-news-right .header-news-order select { width:auto; margin-left: 10px; padding:7px 40px 6px 10px; }*/
.header-news .header-news-right .header-news-search {
  margin-left: 10px;
}

.container-news {
  display: flex;
  flex-wrap: wrap;
}

.container-news .news-item {
  width: 30%;
  margin: 0 3% 4% 0;
}

.container-news .media-item {
  width: 22%;
  margin: 0 3% 3% 0;
}

.container-news .file-item {
  width: 22%;
  margin: 0 3% 5% 0;
  height: 190px;
}

/*  NEWS SINGLE  */
.single-news-header {
  display: flex;
  background-color: #f5f5f5;
}

.single-news-header .single-news-header__left {
  flex-basis: 50%;
}

.single-news-header .single-news-header__left .tags {
  margin: 0 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.single-news-header .single-news-header__left .tags span {
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 120%;
  border-radius: 0px;
  background-color: #f5f5f5;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
}

.single-news-header .single-news-header__left .tags span.link {
  background-color: #0B0B80;
  color: white;
}

.single-news-header .single-news-header__left .tags span.link a {
  color: white;
}

.single-news-header .single-news-header__left .tags span.date {
  background-color: white;
  color: #000000;
}

.single-news-header .single-news-header__left .title {
  margin-bottom: 20px;
}

.single-news-header .single-news-header__left p {
  line-height: 160%;
}

.single-news-header .single-news-header__right {
  flex-basis: 50%;
  background-size: cover;
  background-position: center;
  min-height: 450px;
}

.single-news-content p {
  line-height: 160%;
}

.single-news-content .tabla {
  margin: 40px 0;
}

.single-news-content ul {
  padding-left: 25px;
  margin: 30px 0;
}

.single-news-content ul li {
  list-style: disc;
  margin-bottom: 20px;
  line-height: 160%;
}

.single-news-content h1,
.single-news-content h2,
.single-news-content h3,
.single-news-content h4 {
  margin: 40px 0 30px;
}

.single-news-content h1:first-child,
.single-news-content h2:first-child,
.single-news-content h3:first-child,
.single-news-content h4:first-child {
  margin: 0 0 30px;
}

.single-news-content img {
  margin: 30px 0;
  width: 100%;
}

.single-news-content .wp-caption {
  max-width: 100%;
  background-color: white;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0;
  overflow: hidden;
}

.single-news-content .wp-caption p.wp-caption-text {
  text-align: left;
  line-height: 120%;
  font-size: 16px;
  padding: 10px 20px;
  opacity: 0.4;
}

.single-news-content blockquote {
  margin: 50px 0;
  padding-left: 20px;
  border-left: 1px solid #0B0B80;
}

/*  MEDIA SINGLE POPUP */
.media-info-section-background {
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}

.media-info-section-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-info-container {
  position: relative;
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 620px;
  max-height: 90%;
  max-width: 1000px;
  z-index: 1;
  overflow: auto;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close span {
  width: 22px;
  height: 22px;
  background: url(../images/close.svg) 50%/cover;
  display: block;
}

.close .svg path {
  fill: white;
}

.media-nav {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.media-nav .nav-prev, .media-nav .nav-next {
  cursor: pointer;
  display: inline-block;
  background-color: #0B0B80;
  padding: 10px 15px;
}

.media-nav .nav-prev .svg path, .media-nav .nav-next .svg path {
  fill: white;
}

.media-nav .nav-prev .svg {
  transform: rotate(180deg);
}

.media-close-section {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.media-content {
  padding: 50px 9% 0;
  overflow: auto;
  height: 100%;
}

.media-box.hide {
  display: none;
}

.media-box .name {
  font-size: 22px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
}

.video-responsive iframe, .video-responsive object, .video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  /* items */
  .file-item {
    min-height: 160px;
  }

  .file-item a .title,
.media-item a .title,
.news-item a .title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1100px) {
  /*.header-news .header-news-right .header-news-filter .filter-group button,
  .header-news .header-news-right .header-news-order select,*/
  .header-news-search input[type=search] {
    font-size: 13px;
  }

  .news-item a .image-box {
    height: 200px;
  }

  /*.container-news .file-item,
  .container-news .news-item { width: 25%; }*/
}
@media only screen and (max-width: 1000px) {
  .header-news {
    flex-direction: column;
  }

  .header-news .header-news-right {
    margin-top: 20px;
    justify-content: flex-start;
  }

  /*.header-news .header-news-right .header-news-filter .filter-group button { margin-left:0; margin-right:5px; margin-bottom: 5px; }
  .header-news .header-news-right .header-news-order select { margin-left:0; margin-right: 10px;}*/
  .header-news .header-news-right .header-news-search {
    margin-left: 0;
    margin-right: 10px;
  }
}
@media only screen and (max-width: 900px) {
  .header-news-filter {
    width: 100%;
    margin-bottom: 10px;
  }

  .container-news .media-item,
.container-news .file-item {
    width: 30%;
  }

  .file-item a .title,
.news-item a .title,
.media-item a .title {
    font-size: 16px;
  }

  .news-item a .title {
    height: 90px;
  }

  .media-item a .title {
    height: 67px;
  }

  .single-news-header {
    flex-direction: column;
  }

  .single-news-header .single-news-header__right {
    flex-basis: unset;
  }
}
@media only screen and (max-width: 700px) {
  .container-news .file-item {
    width: 48%;
    margin: 0 2% 3% 0;
    min-height: 150px;
    height: 150px;
  }

  .file-item {
    min-height: 150px;
  }

  .container-news .media-item,
.container-news .news-item {
    width: 48%;
    margin: 0 2% 3% 0;
    min-height: unset;
    height: auto;
  }

  .single-news-header .single-news-header__right {
    min-height: 300px;
  }
}
@media only screen and (max-width: 480px) {
  .container-news .file-item {
    width: 100%;
    margin: 0 0 25px;
    min-height: unset;
    height: auto;
  }

  .file-item {
    min-height: unset;
  }

  .container-news .media-item,
.container-news .news-item {
    width: 100%;
    margin: 0 0 25px;
  }

  .header-news .header-news-right {
    width: 100%;
  }

  .header-news .header-news-right .header-news-search {
    width: 100%;
    margin-right: 0;
  }

  .header-news-order.sort-options {
    width: 100%;
    margin-bottom: 10px;
  }

  .header-news .header-news-right .header-news-order select {
    width: 100%;
    margin-right: 0;
  }

  .file-item a .title,
.news-item a .title,
.media-item a .title {
    font-size: 15px;
  }

  .file-item a .title {
    max-height: unset;
    -webkit-line-clamp: unset;
    text-overflow: unset;
  }

  .news-item a .title {
    height: auto;
    -webkit-line-clamp: unset;
    text-overflow: unset;
  }

  .media-item a .title {
    height: auto;
    -webkit-line-clamp: unset;
    text-overflow: unset;
  }
}
/* ------------------------------------------------------------------------- */
/* PAGE PAGE STYLES */
/* ------------------------------------------------------------------------- */
/* QUI SOM  */
.presentation-section-container {
  position: relative;
}

/*.presentation-section-container:after { content: ''; position: absolute; top:75%; left: 35%; width:1px; height:40%; background-color:$color; animation: blinkingimage 5s infinite; -webkit-animation: blinkingimage 5s infinite; transform: scaleY(0.3);}*/
.presentation-section-container h1 {
  position: absolute;
  left: 5%;
  top: 15%;
  font-size: 70px;
}

.presentation-img {
  width: 55%;
  margin-left: 45%;
}

/* MEMBERS */
.member-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.member-list-item {
  flex-basis: 30%;
  margin: 0 5% 30px 0;
  border-left: 1px solid #0B0B80;
  border-bottom: 1px solid #0B0B80;
  padding: 5px 2% 10px;
  position: relative;
  background-color: white;
}

.member-list-item:nth-child(3n) {
  margin-right: 0;
}

.member-list-item img {
  max-height: 60px;
  width: auto;
  margin-bottom: 30px;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.member-list-item:hover img {
  transform: scale(1.1);
}

.member-list-item p.member-name {
  font-weight: 500;
  margin-bottom: 5px;
  transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
  -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);
}

.member-list-item:hover p.member-name {
  color: #0B0B80;
}

.member-list-item p.member-title {
  color: #898989;
  margin: 0;
}

.member-list-item .cross {
  position: absolute;
  top: 5px;
  right: 0px;
  color: #0B0B80;
  font-size: 30px;
  line-height: 80%;
}

.member-open {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.members-info-section-background {
  display: none;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.7);
}

.members-info-section-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.members-info-container {
  position: relative;
  background-color: #fff;
  margin: auto;
  width: 90%;
  height: 550px;
  max-height: 90%;
  max-width: 1000px;
  z-index: 1;
  overflow: auto;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.close span {
  width: 22px;
  height: 22px;
  background: url(../images/close.svg) 50%/cover;
  display: block;
}

.close .svg path {
  fill: white;
}

.members-nav {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.members-nav .nav-prev, .members-nav .nav-next {
  cursor: pointer;
  display: inline-block;
  background-color: #0B0B80;
  padding: 10px 15px;
}

.members-nav .nav-prev .svg path, .members-nav .nav-next .svg path {
  fill: white;
}

.members-nav .nav-prev .svg {
  transform: rotate(180deg);
}

.members-close-section {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.members-content {
  padding: 50px 9%;
  overflow: auto;
  height: 100%;
}

.member-box.hide {
  display: none;
}

.member-box p.name {
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.member-box p.subtitle {
  font-size: 24px;
  color: #898989;
  line-height: 120%;
  margin-bottom: 20px;
}

.member-box .box {
  margin-top: 30px;
}

.member-box .box h3 {
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-size: 16px;
  display: block;
  border-top: 1px solid #0B0B80;
  border-left: 1px solid #0B0B80;
  padding: 10px 15px 5px;
  margin-bottom: 0;
}

.member-box .box p {
  padding: 5px 15px;
  font-size: 16px;
}

/* FUNCIONS */
.funcions-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}

.funcions-item {
  position: relative;
  flex-basis: 30%;
  margin: 0 5% 5% 0;
  min-height: 220px;
  display: flex;
  align-items: flex-end;
  border-left: 1px solid #0B0B80;
  border-bottom: 1px solid #0B0B80;
  padding: 5px 2% 10px;
  position: relative;
  background-color: white;
}

.funcions-item:nth-child(3n) {
  margin-right: 0;
}

.funcions-item .funcions-number {
  position: absolute;
  left: 5%;
  top: -10px;
  font-family: "Space Grotesk", serif;
  font-size: 200px;
  line-height: 80%;
  color: #f5f5f5;
  margin: 0;
}

.funcions-item .funcions-text {
  font-size: 16px;
  z-index: 1;
}

@media only screen and (max-width: 1400px) {
  /* QUI SOM  */
  .presentation-section-container h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 1100px) {
  /* QUI SOM */
  .presentation-section-container h1 {
    font-size: 50px;
  }

  .presentation-img {
    width: 60%;
    margin-left: 40%;
  }

  .presentation-section-container:after {
    left: 30%;
  }

  /* MEMBERS */
  .member-list {
    justify-content: space-between;
  }

  .member-list-item, .member-list-item:nth-child(3n) {
    flex-basis: 48%;
    margin: 0 0 25px;
  }
}
@media only screen and (max-width: 1000px) {
  /* QUI SOM  */
}
@media only screen and (max-width: 900px) {
  /* QUI SOM  */
  .presentation-section-container h1 {
    font-size: 40px;
  }

  /* FUNCIONS */
  .funcions-list {
    justify-content: space-between;
  }

  .funcions-item, .funcions-item:nth-child(3n) {
    flex-basis: 48%;
    margin: 0 0 25px;
  }
}
@media only screen and (max-width: 800px) {
  /* QUI SOM  */
  .presentation-section-container {
    padding-top: 40px;
  }

  .presentation-section-container h1 {
    font-size: 35px;
    position: relative;
  }

  .presentation-section-container:after {
    display: none;
  }

  .presentation-img {
    width: 70%;
    margin-left: 30%;
  }

  .members-nav {
    top: auto;
    bottom: 0;
  }

  .members-nav .nav-prev, .members-nav .nav-next {
    padding: 10px 12px;
  }

  .member-box p.name {
    font-size: 18px;
  }

  .member-box p.subtitle {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .member-box .box {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 600px) {
  /* QUI SOM  */
  .presentation-section-container h1 {
    font-size: 30px;
  }

  .presentation-img {
    width: 100%;
    margin-left: 0;
  }

  /* MEMBERS */
  .member-list-item, .member-list-item:nth-child(3n) {
    flex-basis: 100%;
    margin: 0 0 15px;
  }

  .members-content {
    padding: 30px 5%;
  }

  .member-box p.name {
    font-size: 16px;
  }

  .member-box p.subtitle {
    font-size: 14px;
  }

  .member-box .box h3 {
    border-left: none;
    font-size: 15px;
    padding: 5px 0 0;
  }

  .member-box .box p {
    padding: 0;
    font-size: 15px;
  }

  /* FUNCIONS */
  .funcions-item .funcions-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 480px) {
  /* QUI SOM  */
  .presentation-image-container ul.presentation-images li {
    flex-basis: 50%;
  }

  .funcions-list {
    flex-direction: column;
  }

  .funcions-item {
    min-height: 170px;
  }

  .funcions-item .funcions-number {
    font-size: 150px;
  }
}
/* ------------------------------------------------------------------------- */
/* GENERAL FORM STYLES */
/* ------------------------------------------------------------------------- */
.hundreds-form__error {
  display: none;
  color: #ff3b3b;
}

.hundreds-validation__input_error {
  border-bottom: 1px solid #ff3b3b !important;
}

label.hundreds-validation__input_error {
  border-bottom: 0px solid #ff3b3b !important;
  color: #ff3b3b !important;
}

.hundreds__messages--errors {
  color: #ff3b3b;
}

.hundreds-form__form {
  position: relative;
}

.hundreds-form__form-box-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.submit-button-container {
  position: relative;
}

.hundreds__loader {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-container-twocolumns {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.contact-container-twocolumns .contact-column {
  flex-basis: 49%;
}

.contact-box {
  margin-bottom: 25px;
}

.contact-box label {
  font-weight: 500;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 16px;
  line-height: 120%;
}

#inputs-cv {
  display: none;
}

.hundreds__messages--hide, .hundreds__loader_hidden {
  display: none;
}

.hundreds__messages--show {
  display: block;
}

.hundreds__messages {
  margin: 30px 0 0;
}

.hundreds__messages p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

select {
  padding: 17px;
  background: #f5f5f5 url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat;
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
  line-height: 140%;
  border-radius: 0px;
  color: #000000;
  width: 100%;
  border: none;
  box-sizing: border-box;
  outline: 0;
  -webkit-appearance: none;
}

/* checkbox labels privacy policy*/
.hundreds-form__checkbox input[type=checkbox] {
  display: none;
}

.hundreds-form__checkbox label {
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  vertical-align: middle;
  padding-left: 30px;
  margin-bottom: 15px;
  position: relative;
  text-align: left;
}

.hundreds-form__checkbox label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #0B0B80;
  background-color: transparent;
  border-radius: 0px;
}

.hundreds-form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 11px;
  width: 11px;
  background-color: white;
  border-radius: 0px;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

.hundreds-form__checkbox label a {
  color: #0B0B80;
  border-bottom: 1px solid #000000;
}

.hundreds-form__checkbox input[type=checkbox]:checked + label {
  color: #000000;
}

.hundreds-form__checkbox input[type=checkbox]:checked + label:after {
  background-color: #0B0B80;
}

.radiobutton-container {
  display: flex;
  flex-wrap: wrap;
}

.hundreds-form__radio input[type=radio] {
  display: none;
}

.hundreds-form__radio label {
  color: #000000;
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  vertical-align: middle;
  padding-left: 25px;
  margin: 0 25px 15px 0;
  position: relative;
  text-align: left;
}

.hundreds-form__radio label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #0B0B80;
  background-color: transparent;
  border-radius: 50px;
}

.hundreds-form__radio label:after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: 3px;
  height: 11px;
  width: 11px;
  background-color: white;
  border-radius: 50px;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

.hundreds-form__radio label a {
  color: #0B0B80;
  border-bottom: 1px solid #000000;
}

.hundreds-form__radio input[type=radio]:checked + label {
  color: #000000;
}

.hundreds-form__radio input[type=radio]:checked + label:after {
  background-color: #0B0B80;
}

form input[type=email], form input[type=text], form input[type=number], form textarea {
  border: none;
  border-bottom: 1px solid #000000;
  padding: 5px;
  border-radius: 0px;
  color: #000000;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  font-size: 18px;
  background: transparent;
  outline: 0;
  width: 100%;
  transition: all 0.5s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -webkit-appearance: none;
}

form textarea {
  resize: none;
}

form input[type=submit] {
  display: inline-block;
  position: relative;
  background: #0B0B80;
  font-size: 18px;
  font-family: "Barlow", sans-serif;
  font-weight: 500;
  cursor: pointer;
  border-radius: 0px;
  border: none;
  color: white;
  border: 1px solid transparent;
  opacity: 1;
  margin: 0;
  outline: 0;
  padding: 10px 25px;
  text-transform: uppercase;
  -webkit-appearance: none;
  -moz-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -o-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  -webkit-transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
  transition: all 0.2s cubic-bezier(0.15, 0.9, 0.34, 0.95);
}

form input[type=submit]:hover {
  background: #000000;
}

::-webkit-input-placeholder {
  opacity: 0.8;
}

:-moz-placeholder {
  opacity: 0.8;
}

::-moz-placeholder {
  opacity: 0.8;
}

:-ms-input-placeholder {
  opacity: 0.8;
}

/* SPECIFIC SEARCH FORM */
.header-news-search input[type=search] {
  position: relative;
  width: 100%;
  background: #ffffff url(../images/search.svg) no-repeat;
  background-position: 95% center;
  border-radius: 0px;
  padding: 8px 40px 8px 10px;
  border: none;
  border-bottom: 1px solid #0B0B80;
  margin: 0;
  line-height: 120%;
}

/*.members-search form { position:relative; }
.members-search form input[type=text] { width:100%; padding: 10px 40px 10px 15px;}
.members-search form input[type=submit] { position:absolute; top:0; right:0; width:30px; height:100%; background:transparent url(../images/search.svg) no-repeat center; border: none; margin:0; }
*/
/* INPUT FILE */
input[type=file] {
  position: relative;
  font-family: "Barlow", sans-serif;
  font-size: 14px;
  height: 50px;
  border: none;
  border-radius: 0px;
  background-color: #f5f5f5;
  color: #000000;
  line-height: 50px;
  width: 100%;
  padding: 0 25px;
}

input[type=file]::-webkit-file-upload-button {
  visibility: hidden;
}

input[type=file]::before {
  content: "Seleccionar arxiu";
  position: absolute;
  left: 5px;
  display: inline-block;
  background-color: transparent;
  border-radius: 0px;
  padding: 0 10px;
  outline: none;
  color: #0B0B80;
  margin-right: 10px;
  white-space: nowrap;
  -webkit-user-select: none;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  opacity: 1;
}

p.curriculum-error-label {
  margin: 2px 0;
  font-size: 13px;
  color: #ff3b3b !important;
}

.no_visible {
  display: none;
}

input[type=text].bots-input {
  position: absolute;
  height: 0;
  font-size: 1px;
  line-height: 0%;
  padding: 0;
  margin: 0;
  opacity: 0;
  visibility: hidden;
}

@media only screen and (max-width: 800px) {
  form input[type=email], form input[type=text], form input[type=number], form textarea {
    padding: 10px;
  }

  select {
    padding: 12px;
  }

  form label {
    font-size: 14px;
  }

  .hundreds-form__checkbox {
    margin: 0;
    width: auto;
  }
}
@media only screen and (max-width: 600px) {
  .contact-container-twocolumns {
    flex-direction: column;
  }

  .contact-container-twocolumns .contact-column {
    width: 100%;
  }

  .contact-box {
    margin-bottom: 15px;
  }

  .contact-box label {
    margin-bottom: 5px;
    font-size: 14px;
  }

  .hundreds-form__radio label {
    margin: 0 25px 15px 0;
  }

  .radiobutton-container {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .checks-container {
    text-align: left;
  }

  form input[type=submit] {
    width: 100%;
  }
}
/* ------------------------------------------------------------------------- */
/* NEWSLETTER FORM STYLES */
/* ------------------------------------------------------------------------- */
.hundreds__newsletter-form {
  margin-top: 20px;
}

.hundreds__newsletter-form input[type=email] {
  margin-bottom: 0;
  width: auto;
  border-radius: 5px 0 0 5px;
  flex: 1;
}

.hundreds__newsletter-form .hundreds-form__form-box-container {
  display: flex;
  align-items: flex-start;
}

.hundreds__newsletter-form .submit-button-container {
  position: relative;
  margin-top: 0;
  max-width: 430px;
}

.hundreds__newsletter-form .submit-button-container input[type=submit] {
  border-radius: 0 5px 5px 0;
  width: 100px;
}

.hundreds__newsletter-form .hundreds-form__checkbox label {
  font-size: 12px;
  color: white;
}

.hundreds__newsletter-form .hundreds-form__checkbox label a {
  border-color: white;
  color: white;
}

.hundreds__newsletter-form .hundreds-form__checkbox label:before {
  top: -2px;
  border-color: white;
}

.hundreds__newsletter-form .hundreds-form__checkbox label:after {
  top: 1px;
  background-color: transparent;
}

.hundreds__newsletter-form .hundreds-form__checkbox input[type=checkbox]:checked + label {
  color: white;
}

.hundreds__newsletter-form .hundreds-form__checkbox input[type=checkbox]:checked + label:after {
  background-color: white;
}

@media only screen and (max-width: 800px) {
  .hundreds__newsletter-form .submit-button-container input[type=submit] {
    padding: 10px;
  }
}
/* ------------------------------------------------------------------------- */
/* INSTAGRAM STYLES */
/* ------------------------------------------------------------------------- */
.hundreds__instagram a {
  width: 25%;
  display: inline-block;
  height: 200px;
}

.hundreds__instagram a li {
  width: 100%;
  height: 200px;
  background-size: cover;
}

/* ------------------------------------------------------------------------- */
/* FOOTER STYLES */
/* ------------------------------------------------------------------------- */
.footer {
  background-color: #f5f5f5;
}

.footer h3 {
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  line-height: 110%;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.footer hr {
  width: 100%;
  height: 1px;
  background: #000000;
  border: none;
  margin: 10px 0;
}

.footer-top, .footer-bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

.footer-top .footer-left {
  padding: 0 5%;
}

.footer-bottom .footer-left {
  flex-basis: 48%;
  padding: 0 0 0 5%;
}

.footer-bottom .footer-right {
  flex-basis: 48%;
  padding: 0 5% 0 0;
}

ul.logos-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

ul.logos-container li {
  margin: 10px;
  display: flex;
}

ul.logos-container li img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 42px;
}

.footer-bottom .footer-left {
  display: flex;
  flex-wrap: wrap;
}

.footer-box {
  flex-basis: 40%;
  margin-bottom: 30px;
}

.footer-box:nth-child(1), .footer-box:nth-child(3) {
  flex-basis: 60%;
  padding-right: 5%;
}

.footer-box:nth-child(3) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer-box p {
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 10px;
}

.footer-box p.mini {
  font-size: 14px;
  margin-bottom: 5px;
}

.footer-left .web-logo .footer_logo {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  max-width: 160px;
}

ul.contact li {
  margin-bottom: 15px;
}

ul.contact li a {
  display: flex;
  align-items: flex-start;
  color: #000000;
  font-size: 16px;
  line-height: 130%;
}

ul.contact li a:hover {
  text-decoration: underline;
}

ul.contact li a img {
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

ul.contact li a span {
  display: inline-block;
}

ul.footer_social li {
  margin-bottom: 3px;
}

ul.footer_social li a {
  font-size: 16px;
  line-height: 130%;
  color: #000000;
}

ul.footer_social li:last-child a {
  color: #0B0B80;
}

ul.footer_social li a:hover {
  text-decoration: underline;
}

.footer-map {
  position: relative;
  flex-basis: 48%;
  background: white url(../images/map.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-map a {
  display: block;
  width: 100%;
  height: 100%;
}

.footer-map a .map-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}

.footer-map a .map-icon .icon-shadow {
  position: absolute;
  width: 52px;
  height: 52px;
  top: -6px;
  left: -6px;
  border-radius: 100px;
  background-color: rgba(10, 12, 120, 0.3);
  animation: blinkingshadow 4s ease-in-out infinite;
  -webkit-animation: blinkingshadow 4s ease-in-out infinite;
  transform: scale(1);
  opacity: 1;
}

.footer-map a .map-icon .icon {
  width: 40px;
  height: 40px;
  background-color: #0a0c80;
  border-radius: 100px;
  z-index: 1;
}

.footer-mobile {
  padding: 20px 5%;
}

.footer-mobile .web-logo {
  display: inline-block;
  margin: 20px 0 5px;
}

.footer-mobile .web-logo .footer_logo {
  max-width: 130px;
}

.footer-mobile p.mini {
  font-size: 12px;
}

.hide-on-desktop {
  display: none;
}

.hide-on-mobile {
  display: block;
}

@media only screen and (max-width: 1250px) {
  /*ul.logos-container li img { max-height: 35px;}*/
}
@media only screen and (max-width: 1000px) {
  .footer-top, .footer-bottom {
    flex-direction: column;
  }

  .footer-left {
    padding: 0 5%;
  }

  .footer-right {
    padding: 0 5%;
    margin-top: 20px;
  }

  .footer-map {
    min-height: 300px;
  }

  ul.logos-container li img {
    max-height: 35px;
  }
}
@media only screen and (max-width: 600px) {
  .footer-top, .footer-bottom {
    padding-top: 30px;
  }

  .footer h3,
.footer-box p,
ul.contact li a,
ul.footer_social li a {
    font-size: 15px;
  }

  .footer-map {
    min-height: 200px;
  }

  .hide-on-desktop {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .footer-bottom .footer-left {
    flex-direction: column;
  }

  .footer-box {
    margin-bottom: 20px;
  }

  .footer-box:nth-child(1) {
    padding-right: 0;
  }

  .footer-box:nth-child(3) {
    display: none;
  }

  ul.footer_social {
    display: flex;
    flex-wrap: wrap;
  }

  ul.footer_social li {
    margin: 0 10px 2px 0;
  }
}