/* ------------------------------------------------------------------------- */


/* HEADER STYLES */


/* ------------------------------------------------------------------------- */



.header { width: 100%; position:fixed; z-index:10; background-color:rgba(255,255,255,1.0); box-shadow:0px 0px 10px rgba(0,0,0,0.1); }
.header.fixed { position:fixed; padding: 5px 0; z-index:10;  }
.header.menu-opened { position: fixed;}

.header .container-header { display:flex; justify-content: space-between; align-items: center; padding: 15px 5%; transition: all .5s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9);}
.header.fixed .container-header { padding: 10px 5%; }

.header .header-right { display:flex; align-items: center; justify-content: flex-end; }

.header .web-logo {/*z-index: 11;*/}
.header .web-logo .header_logo { display: block; width:210px; }
.header.fixed .web-logo .header_logo { width:150px; height:50px; }

/* MENUS */

.header__nav { }
.header__nav ul {display: flex; }
.header__nav ul li {padding:0 10px; position:relative; margin-left: 3px;}
.header__nav ul li a { color:$color-txt; font-size:16px; text-transform:uppercase; line-height:$line-height2; font-weight:$font-weight-semibold; position: relative; overflow: hidden; display: block; padding: 5px 0 5px;}
.header__nav ul li a:after { backface-visibility: hidden; transform-style: preserve-3d; transform: translateZ(0); will-change: transform; content: ""; width: 100%; height: 1px; position: absolute; bottom: 0; left: 0; background:$color; 
transition: all .5s cubic-bezier(.07,.71,.1,.9);
    -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9);
    transform: translate3d(-103%,0,0);
    -webkit-transform: translate3d(-103%,0,0);
}
.header__nav ul li a:hover { color:$color;} 
.header__nav ul li a:hover:after, 
.header__nav ul li.selected a:after {
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
}
.header__nav ul li.contact { background-color:$color; margin-left: 10px;}
.header__nav ul li.contact a { color:white;}

.header__nav ul ul.sub-menu {visibility:hidden; opacity:0; padding: 15px 25px; border-radius:$border-radius; margin-left: -25px; position:absolute; background:white; width:auto; box-shadow: 0 5px 5px rgba(0,0,0,0.1); display:flex; flex-wrap:wrap; -webkit-transition: all .2s; -moz-transition: all .2s; -ms-transition: all .2s; -o-transition: all .2s; transition: all .2s;}
.header__nav ul li:hover ul.sub-menu {visibility:visible; opacity:1;}
.header__nav ul ul.sub-menu li { text-align: left; display: block; width: 100%; margin: 0; padding:0;}
.header__nav ul ul.sub-menu li a { display: inline-block; font-size: 14px; line-height:$line-height; padding:5px 0; overflow: hidden; color:$color-txt;}
.header__nav ul ul.sub-menu li a:after { backface-visibility: hidden; transform-style: preserve-3d; transform: translateZ(0); will-change: transform; content: ""; width: 100%; height: 1px; position: absolute; bottom:0; left: 0; background:$color-txt; transition: all .5s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9); transform: translate3d(-103%,0%,0); -webkit-transform: translate3d(-103%,0%,0);}
.header__nav ul ul.sub-menu li.current_page_item a:after,
.header__nav ul ul.sub-menu li a:hover:after { transform: translate3d(0%,0%,0); -webkit-transform: translate3d(0%,0%,0);}



.header .header_social { margin-left:15px; }
.header .header_social ul { display: flex; align-items: center;}
.header .header_social ul li {display:inline-block; margin:0 5px;}
.header .header_social ul li a { position:relative; overflow:hidden; display:flex; }


.header .header_idiomas { margin-left:10px; }
.header .header_idiomas ul { display: flex;  }
.header .header_idiomas ul li {display:inline-block; margin:0 5px;}
.header .header_idiomas ul li a { color:$color; font-size:14px; font-weight:$font-weight-semibold; line-height:120%; text-decoration:none; position:relative; overflow:hidden; display:block; padding: 8px 0 5px; text-transform: uppercase; opacity:0.3;}

.header .header_idiomas ul li a.active, 
.header .header_idiomas ul li a:hover, 
.header .header_idiomas ul li.selected a {
    opacity:1;
}


#nav-open-close { display:none; margin-left: 30px; opacity:1; z-index:11; position: relative; width: 30px;  height: 23px; cursor: pointer;}
#nav-open-close span {
  display: block; position: absolute; height: 2px; width: 100%; background: $color; opacity: 1; left: 0;
  -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg);
  -webkit-transition: 1.0s cubic-bezier(.77,0,.18,1); -moz-transition: 1.0s cubic-bezier(.77,0,.18,1); -o-transition: 1.0s cubic-bezier(.77,0,.18,1); transition: 1.0s cubic-bezier(.77,0,.18,1);
  /*transition: -webkit-transform 1.4s cubic-bezier(.77,0,.18,1); transition: transform 1.4s cubic-bezier(.77,0,.18,1); transition: transform 1.4s cubic-bezier(.77,0,.18,1),-webkit-transform 1.4s cubic-bezier(.77,0,.18,1);*/
}
#nav-open-close span:nth-child(1) { top: 0px;}
#nav-open-close span:nth-child(2) { top: 11px;}
#nav-open-close span:nth-child(3) { top: 22px;}

#nav-open-close.open span { background: white;}

#nav-open-close.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  left:0px;
}
#nav-open-close.open span:nth-child(2) {
  opacity: 0;
  left: 0px;
}
#nav-open-close.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  left:0px;
}



.header .menu-desplegable {position:fixed; top:0; left:100%; width:400px; height:100%; overflow: hidden; transform: translateX(100%); background:$color; padding:40px 10px 20px 20px; z-index: 10; animation-fill-mode: both; transition: -webkit-transform 1.1s cubic-bezier(.77,0,.18,1); transition: transform 1.0s cubic-bezier(.77,0,.18,1); transition: transform 1.0s cubic-bezier(.77,0,.18,1),-webkit-transform 1.0s cubic-bezier(.77,0,.18,1);}
.header .menu-desplegable.open {transform: translateX(-400px);}
/*
.menu__logo { width:100px; height:100px; margin-bottom:15px;}
.menu__logo path,
.menu__logo polygon { fill:white; }*/
.header .menu-desplegable__content { width:100%; height:100%; display:flex; justify-content: center; align-items: center; }

.header .menu-desplegable .header__nav-mobile {position:relative;}
.header .menu-desplegable .header__nav-mobile ul { margin:0; display:flex; flex-direction: column; align-items: center; }
.header .menu-desplegable .header__nav-mobile ul li { margin:0 0 5px; display:inline-block; width:auto; text-align: center; }

.header .menu-desplegable .header__nav-mobile ul ul { margin:5px 0 10px; }
.header .menu-desplegable .header__nav-mobile ul ul li a { font-size:16px; }

.header .menu-desplegable .header__nav-mobile ul li a {font-family:$main; color:white; font-size:25px; line-height:$line-height2; text-decoration:none; text-transform:uppercase; position:relative; overflow:hidden; display:inline-block; padding: 0 0 2px; -webkit-transition: all 0.2s; -moz-transition: all 0.2s; -ms-transition: all 0.2s; -o-transition: all 0.2s; transition: all 0.2s;}
.header .menu-desplegable .header__nav-mobile ul li a:after {
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transform: translateZ(0);
    will-change: transform;
    content: "";
    width: 0%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: white;
    opacity:1;
    transition: all .5s cubic-bezier(.07,.71,.1,.9);
    -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9);
}
.header .menu-desplegable .header__nav-mobile ul li.current_page_item a:after,
.header .menu-desplegable .header__nav-mobile ul li.project-selected a:after,
.header .menu-desplegable .header__nav-mobile ul li a:hover:after { width: 100%; }




main { margin-top:100px;  }

@media only screen and (max-width: 1200px){
  .header__nav ul li a { font-size: 14px; }
}

@media only screen and (max-width: 1100px){
	.header .web-logo .header_logo { width: 150px; height:50px; }
  .header.fixed .web-logo .header_logo { width:150px; height:50px;}
	.header__nav ul li { padding: 0 6px; }
	.header__nav ul li a { font-size: 13px; }
  main { margin-top:80px;  }
}
@media only screen and (max-width: 1000px){
	#nav-open-close { display:block; }
	.header__nav { display:none; }
	.header .header_social { margin-left: 0; padding-left: 0; border-left: none; }
	.header .menu-desplegable .header_idiomas { display:block; }

	.header .menu-desplegable { width:100%;}
	.header .menu-desplegable.open {transform: translateX(-100%);}

  .header .web-logo .header_logo { width: 130px; height:45px; }
  .header.fixed .web-logo .header_logo { width: 130px; height:45px;  }
  main { margin-top:70px;  }
}

@media only screen and (max-width: 600px){
  .header .container-header { padding: 10px 5%;}
  .header.fixed .container-header { padding: 10px 5%;}
  .header .web-logo .header_logo { width: 120px; height:40px; }
  .header.fixed .web-logo .header_logo { width: 120px; height:40px;  }
  main { margin-top:55px;  }
}
@media only screen and (max-width: 480px){
  #nav-open-close { margin-left: 20px;}
  .header .header_idiomas { padding-left: 0; border-left: none;}
}