/* ------------------------------------------------------------------------- */


/* VARIABLES */


/* ------------------------------------------------------------------------- */

$main: 'Barlow', sans-serif;  /* 400 y 500*/
$second: 'Space Grotesk', serif; /* 600 */

$color: #0B0B80;
$color-yellow: #F8A251;
$color-red: #C1002B;
$color-green: #036648;
$color-txt: #000000;
$color-bt: #0B0B80;
$color-bg: #f5f5f5;

$border-radius: 0px;
$line-height: 140%;
$line-height2: 120%;
$line-height3: 160%;
$letter-spacing: 0px;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 500;