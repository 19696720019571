/* ------------------------------------------------------------------------- */


/* PAGE PAGE STYLES */


/* ------------------------------------------------------------------------- */

/* QUI SOM  */

.presentation-section-container { position:relative; }
/*.presentation-section-container:after { content: ''; position: absolute; top:75%; left: 35%; width:1px; height:40%; background-color:$color; animation: blinkingimage 5s infinite; -webkit-animation: blinkingimage 5s infinite; transform: scaleY(0.3);}*/
.presentation-section-container h1 { position:absolute; left:5%; top:15%; font-size:70px; }
.presentation-img { width: 55%; margin-left:45%;}

/* MEMBERS */

.member-list { display: flex; flex-wrap: wrap; padding-top: 30px; }
.member-list-item { flex-basis: 30%; margin: 0 5% 30px 0; border-left: 1px solid $color; border-bottom:1px solid $color; padding: 5px 2% 10px; position: relative; background-color:white; }
.member-list-item:nth-child(3n) {margin-right:0;}
.member-list-item img { max-height: 60px; width:auto; margin-bottom:30px; transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.member-list-item:hover img {transform:scale(1.1);}
.member-list-item p.member-name { font-weight: $font-weight-semibold;  margin-bottom:5px; transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9); -webkit-transition: all 0.5s cubic-bezier(0.07, 0.71, 0.1, 0.9);}
.member-list-item:hover p.member-name {color:$color;}
.member-list-item p.member-title { color:#898989; margin:0; }
.member-list-item .cross {position:absolute; top:5px; right:0px; color:$color; font-size:30px; line-height:80%;}
.member-open { width: 100%;height: 100%;position: absolute;top: 0;left: 0; cursor: pointer;}

.members-info-section-background { display: none; left: 0; top: 0; width: 100%; height: 100%; position: fixed; z-index: 1000; background-color: rgba(0,0,0,.7);}
.members-info-section-container { position: relative; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }
.members-info-container {  position: relative; background-color: #fff; margin: auto; width: 90%; height: 550px; max-height:90%; max-width: 1000px; z-index: 1; overflow: auto; }

.close{display: flex;align-items: center;justify-content: center;width: 60px;height: 60px;border-radius: 100px;  position: absolute;  top: 0; right:0;  cursor: pointer; }
.close span {width: 22px; height: 22px; background: url(../images/close.svg) 50%/cover; display: block;}
.close .svg path { fill:white; }

.members-nav { position:absolute; top:45%; left:0; width:100%; display:flex; justify-content: space-between;}
.members-nav .nav-prev, .members-nav .nav-next { cursor: pointer; display:inline-block; background-color:$color; padding:10px 15px;}
.members-nav .nav-prev .svg path, .members-nav .nav-next .svg path { fill:white;}
.members-nav .nav-prev .svg { transform:rotate(180deg);}

.members-close-section { position: absolute; width: 100%; height: 100%; left: 0; top: 0;}

.members-content { padding:50px 9%; overflow: auto; height: 100%;}
.member-box.hide { display:none; }
.member-box p.name { font-size:24px; font-weight:$font-weight-semibold; line-height:$line-height2; text-transform:uppercase; margin-bottom:0px;}
.member-box p.subtitle { font-size:24px; color:#898989; line-height:$line-height2; margin-bottom:20px;}
.member-box .box { margin-top: 30px; }
.member-box .box h3 { font-family:$main; font-weight:$font-weight-semibold; font-size:16px; display:block; border-top:1px solid $color; border-left:1px solid $color; padding:10px 15px 5px; margin-bottom:0; }
.member-box .box p { padding:5px 15px; font-size:16px; }


/* FUNCIONS */

.funcions-list { display: flex; flex-wrap: wrap; padding-top: 30px; }
.funcions-item { position:relative; flex-basis: 30%; margin: 0 5% 5% 0; min-height:220px; display:flex; align-items:flex-end; border-left: 1px solid $color; border-bottom:1px solid $color; padding: 5px 2% 10px; position: relative; background-color:white; }
.funcions-item:nth-child(3n) {margin-right:0;}
.funcions-item .funcions-number { position:absolute; left:5%; top:-10px; font-family:$second; font-size:200px; line-height:80%; color:$color-bg; margin:0; }
.funcions-item .funcions-text { font-size:16px; z-index:1; }





@media only screen and (max-width: 1400px){
    /* QUI SOM  */
    .presentation-section-container h1 { font-size:60px; }
}

@media only screen and (max-width: 1100px){
    /* QUI SOM */
    .presentation-section-container h1 { font-size:50px; }
    .presentation-img { width: 60%; margin-left:40%;}
    .presentation-section-container:after { left: 30%;}
    /* MEMBERS */
    .member-list {justify-content: space-between;}
    .member-list-item, .member-list-item:nth-child(3n) { flex-basis: 48%; margin: 0 0 25px;}
    
}
@media only screen and (max-width: 1000px){
    /* QUI SOM  */
    
}
@media only screen and (max-width: 900px){
    /* QUI SOM  */
    .presentation-section-container h1 { font-size:40px; }
    /* FUNCIONS */
    .funcions-list {justify-content: space-between;}
    .funcions-item, .funcions-item:nth-child(3n) { flex-basis: 48%; margin: 0 0 25px;}
}
@media only screen and (max-width: 800px){
    /* QUI SOM  */
    .presentation-section-container { padding-top: 40px;}
    .presentation-section-container h1 { font-size:35px; position:relative; }
    .presentation-section-container:after {display:none;}
    .presentation-img { width: 70%; margin-left: 30%;}
    .members-nav { top: auto; bottom: 0;}
    .members-nav .nav-prev, .members-nav .nav-next {padding: 10px 12px;}
    .member-box p.name { font-size: 18px;}
    .member-box p.subtitle { font-size: 16px; margin-bottom: 10px;}
    .member-box .box { margin-top: 15px;}
}
@media only screen and (max-width: 600px){
    /* QUI SOM  */
    .presentation-section-container h1 { font-size:30px;}
    .presentation-img { width: 100%; margin-left: 0;}

    /* MEMBERS */
    .member-list-item, .member-list-item:nth-child(3n) { flex-basis: 100%; margin: 0 0 15px; }
    .members-content { padding: 30px 5%;}
    .member-box p.name { font-size: 16px;}
    .member-box p.subtitle { font-size: 14px; }
    .member-box .box h3 {border-left:none; font-size: 15px; padding:5px 0 0; }
    .member-box .box p { padding: 0; font-size: 15px;}
    /* FUNCIONS */
    .funcions-item .funcions-text { font-size: 14px;}
}   
@media only screen and (max-width: 480px){
    /* QUI SOM  */
    .presentation-image-container ul.presentation-images li { flex-basis: 50%; }
    .funcions-list { flex-direction: column;}
    .funcions-item {min-height:170px;}
    .funcions-item .funcions-number {font-size: 150px;}
}


