/* ------------------------------------------------------------------------- */


/* HOME CSS */


/* ------------------------------------------------------------------------- */

.highlighted span { 
	display: inline-block;
	color:white;
    background: linear-gradient(to bottom, $color-yellow 0%, $color-yellow 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 5px 80%;
    text-decoration: none;
}

.home-section { position:relative; height:750px; overflow:hidden; }
.home-section .home-title { position:absolute; width:100%; height:100%; z-index:2; background: linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%); display: flex; align-items: center; padding-left: 5%;}
.home-section .home-title h1 { color:white; font-size:40px; line-height:120%; }
.home-section .home-title h1 span:nth-child(3) { background: linear-gradient(to bottom, $color-green 0%, $color-green 100%); background-position: 0 100%; background-size: 5px 80%; background-repeat: repeat-x; }
.home-section .home-title h1 span:nth-child(5) { background: linear-gradient(to bottom, $color-red 0%, $color-red 100%); background-position: 0 100%; background-size: 5px 80%; background-repeat: repeat-x; }
.home-section .home-title h1 span:nth-child(7) { background: linear-gradient(to bottom, $color 0%, $color 100%); background-position: 0 100%; background-size: 5px 80%; background-repeat: repeat-x;}

/*.home-section .home-video { position:relative; width:100%; height:100%; }
.home-section .home-video video { position: relative; min-width: 100%; min-height: 100%; width: 100%; height: auto; z-index: 0;  background: url(../images/video-poster.jpg) no-repeat; background-size: cover; transition: 1s opacity;}*/
.home-section .home-video #videobt {  z-index: 3; position: absolute; width: 100%; height: 100%; top: 0; cursor: pointer;}
.home-section .home-video .play-home { display:none; position: absolute; z-index:2; width:60px; height:60px; top:50%; left:50%; margin-left: -30px; margin-top: -30px; cursor:pointer; background: rgba(0,0,0,.3) url(../images/play.svg) no-repeat; background-size: 100%; border-radius:50px; border: 1px solid rgba(255,255,255,0.5);}
.home-section .home-video .play-home:hover { background: rgba(0,0,0,.5) url(../images/play.svg) no-repeat; background-size: 100%;}

.home-section .home-video-mobile {display: none;}
.home-section .home-video, 
.home-section .home-video-mobile { position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 0; overflow: hidden;}
.home-section .home-video video, 
.home-section .home-video-mobile video { position: relative; -o-object-fit: cover; object-fit: cover; min-width: 100%; min-height: 100%; width: 100%; height: 100%; z-index: 0;
    /* transform: translateX(-50%) translateY(-50%); 
    background: url(../img/imagen-portada.jpg) no-repeat;
    background-size: cover;*/
    transition: 1s opacity;
}

/*.home-section .owl-carousel-home { position:relative; width:100%; height:100%; }
.slider-item { position:relative; display:flex; align-items:center; padding:30px 5%; height: 750px; overflow: hidden;}
.slider-item .image { position: absolute; top: 0; left: 0; width: 100%; height: 100%;background-size: cover; background-position: center; animation: blinkingimage 30s infinite; -webkit-animation: blinkingimage 30s infinite; transform: scale(1.2); }
*/
.sound-box-content { z-index: 2; position: absolute; right: 20px; bottom: 50px; width:35px; }
.sound-box-content .home-audio { opacity:0; visibility: hidden;}
.sound-box-content .sound-box-button { display: flex; align-items: center; }
.sound-box-content .sound-box-button .sound-button { width:35px; height:35px; display:flex; align-items: center; justify-content: center; padding:9px; border-radius: 100px; background-color:rgba(0,0,0,0.5); cursor:pointer;}
.sound-box-content .sound-box-button .sound-button img { width: 16px; }
.sound-box-content .sound-box-button .sound-button .pause { position:absolute; width:4px; height:22px; transform: rotate(45deg);} 
.sound-box-content .sound-box-button .sound-button .pause:before { content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 100%; border-radius:5px; background: white;}
.sound-box-content .sound-box-button .sound-button .pause:after {content: ""; position: absolute; top: 0; right: 0; width: 2px; height: 100%; background: rgba(0,0,0,0.5);}
.sound-box-content .sound-box-button .sound-button.playing .pause { display:none; }



.home-links { display:flex; justify-content: space-between;}
.home-links .home-link { flex-basis:22%; border-left:1px solid $color; border-bottom:1px solid $color;  }
.home-links .home-link a { display:block; width:100%; height:100%; padding:25px; background-color:white; }
.home-links .home-link a h2 { margin-bottom:10px; transition: all .5s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9); }
.home-links .home-link a p { font-size:16px; color: $color-txt; }
.home-links .home-link a .home-link-arrow { transition: all .5s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9);}
.home-links .home-link a:hover .home-link-arrow { transform:translateX(10px); }
.home-links .home-link a:hover h2 { color:$color;}

.home-parallax { position:relative; background-size: cover; background-position: center; display:flex; overflow: hidden;}
.home-parallax:before { content:""; position:absolute; width:100%; height:100%; top:0; left:0; background: linear-gradient(to right, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 50%); }
.home-parallax .container { z-index:1; display: flex; justify-content: flex-end; padding-top:200px; padding-bottom:60px; }
.home-parallax a.link-parallax { display: inline-block; max-width:600px; }
.home-parallax a.link-parallax h1 { color:white; }
.home-parallax a.link-parallax p { color:white; margin-bottom:10px;}
.home-parallax a.link-parallax .home-link-arrow {transition: all .5s cubic-bezier(.07,.71,.1,.9); -webkit-transition: all .5s cubic-bezier(.07,.71,.1,.9);}
.home-parallax a.link-parallax:hover .home-link-arrow { transform:translateX(10px); }
.home-parallax a.link-parallax .svg path {fill:white;} 
.home-parallax video { position: absolute; /*top: 50%; left: 50%;*/ object-fit: cover; min-width: 100%; min-height: 100%; width: 100%; height: auto;  /*transform: translateX(-50%) translateY(-50%);*/ background: url(../images/video-poster.jpg) no-repeat; background-size: cover; transition: 1s opacity;}
.home-parallax .opacity { position: absolute; width: 100%; height: 100%; top:0; left:0; background-color:rgba(0,0,0,0.2); z-index: 0; }

.title-home-news { display:flex; align-items:center; margin-bottom:25px;}
.title-home-news h1 { margin-bottom:0; margin-right:15px;}
.title-home-news a { font-size:16px; border-bottom:1px solid $color; }
.home-section-news { display:flex; margin-bottom: 40px; }
.home-section-news .news-item {flex-basis:30%; margin-right:5%; }
.home-section-news .news-item:last-child { margin-right:0; }
.home-section-news .news-item a .title {height:auto; -webkit-line-clamp:unset; text-overflow:unset; }

/* CARRUSEL CSS */

.owl-theme .owl-dots,
.owl-theme .owl-nav.disabled + .owl-dots { position: absolute; bottom: 20px; right: 30px; }
.owl-theme .owl-dots .owl-dot { background:transparent; }
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span { background: $color;}



/* POPUP HOME */

.popup { left: 0; top: 0; width: 100%; height: 100%; position: fixed; z-index: 1000; background-color: rgba(0,0,0,.7);}
.popup-container { position: relative; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; }
.popup-full-close { position: absolute; width: 100%; height: 100%; left: 0; top: 0;}
/* .close -> page.scss - linea 33 */
.popup-info-container {  margin: auto; width: 90%; max-height:90%; max-width: 1000px; z-index: 1; display:flex; }
.popup-info-container a { display: inline-block; text-align:center; } 
.popup-info-container a img { display: inline-block; max-height: 100%; width:auto; } 



@media only screen and (max-width: 1440px){
	.home-section, .slider-item { height:650px;}
}
@media only screen and (max-width: 1300px){
	.home-links .home-link { flex-basis: 23%;}
	.home-links .home-link a { padding-right: 10px;}
}
@media only screen and (max-width: 1100px){
	.home-section { height:550px;}
	.home-links .home-link a p { font-size: 15px;}
}
@media only screen and (max-width: 900px){
	.home-section, .slider-item { height:500px;}
	.home-section .home-title h1 { font-size: 35px;}
	.home-links { flex-wrap: wrap;}
	.home-links .home-link { flex-basis: 48%; margin-bottom: 25px;}
	.home-section-news { flex-wrap: wrap; justify-content: space-between;}
	.home-section-news .news-item { flex-basis: 48%; margin-right: 0; margin-bottom:20px;}
}
@media only screen and (max-width: 800px){
	.home-section, .slider-item { height:450px;}
}
@media only screen and (max-width: 600px){
	.home-section .home-title h1 { font-size: 28px;}
	.home-links .home-link a p { font-size: 14px;}
	.home-parallax video { display:none;}

	.home-section .home-video {display: none;}
	.home-section .home-video-mobile {display: block;}
	.sound-box-content {display: none; }
}
@media only screen and (max-width: 480px){
	.home-section .home-title h1 { font-size: 24px;}
	.home-links .home-link { flex-basis: 100%;}
	.home-links .home-link a { padding: 5px 5px 5px 15px;}
	.home-section-news .news-item { flex-basis: 100%;}
	.title-home-news a { font-size: 13px;}
	.home-parallax a.link-parallax h1 { font-size: 20px;}
}

