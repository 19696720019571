/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE - Classes used throught all the project */


/* ------------------------------------------------------------------------- */


h1, h2, h3, h4, h5 { 
	font-family:$second;
	font-weight:600;
	color:$color-txt; 
	line-height:$line-height2;
	margin-bottom:30px;
}

h1 { font-size:35px; }
h2 { font-size:24px; }
h3 { font-size:18px; }
h4 { font-size:16px; }
h5 { font-size:16px; }

.title_center {text-align: center;}
.title_white {color: white;}

@media only screen and (max-width: 1100px){
	h1 { font-size:32px; }
	h2 { font-size:22px; }
}
@media only screen and (max-width: 800px){
	h1 { font-size:30px; }
	h2 { font-size:20px; }
}

@media only screen and (max-width: 600px){
	h1 { font-size:25px; }
	h2 { font-size:18px; }
	h3 { font-size:16px; }
	h1, h2, h3, h4, h5 { margin-bottom:25px;}
}

@media only screen and (max-width: 480px){
	h1, h2, h3, h4, h5 { margin-bottom:20px;}
}