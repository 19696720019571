/* ------------------------------------------------------------------------- */


/* COOKIES */


/* ------------------------------------------------------------------------- */

.cookies{ position: fixed; bottom: 10px; left:10px; width: 90%; max-width: 400px; padding: 25px 40px 20px 25px; z-index:100; background-color:white; border-radius: $border-radius; box-shadow: 0 0 20px rgba(0,0,0,0.2);}
.cookies .message-close-button { position: absolute; top: 15px; right: 15px; width: 15px; height: 15px; background: transparent url(../images/close.svg) no-repeat; background-size: 100%; cursor:pointer;}
.cookies p { font-size: 14px; color:$color-txt; line-height:130%; margin-bottom: 0;}
.cookies p.cookies__text {margin-bottom:3px;}
.cookies p:last-child {text-align: right;}
.cookies p a{ color: $color-txt; font-weight:$font-weight-bold; font-size:15px;}
.cookies p a:hover{ color: $color-txt;}

@media only screen and (max-width: 800px){
  .cookies p { font-size:13px; }
  .cookies p a{ font-size:14px;}
}
@media only screen and (max-width: 600px){
  .cookies{ width: calc(100% - 20px);  max-width: none;}
  .cookies p { font-size:12px; }
  .cookies p a{ font-size:13px;}
}