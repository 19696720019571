/* ------------------------------------------------------------------------- */


/* FOOTER STYLES */


/* ------------------------------------------------------------------------- */

.footer { background-color:$color-bg; }
.footer h3 { font-family:$main; font-size:16px; line-height:110%; font-weight:$font-weight-semibold; text-transform:uppercase; margin-bottom:0px;}
.footer hr { width:100%; height:1px; background:$color-txt; border:none; margin:10px 0;}
.footer-top, .footer-bottom { display:flex; justify-content: space-between; padding-top:50px; }
.footer-top .footer-left { padding:0 5%; }
.footer-bottom .footer-left { flex-basis:48%; padding:0 0 0 5%; }
.footer-bottom .footer-right { flex-basis:48%; padding:0 5% 0 0; }
ul.logos-container {display: flex; flex-wrap:wrap; align-items: center; justify-content: flex-start;}
ul.logos-container li { margin: 10px; display: flex;}
ul.logos-container li img { display: block; width: 100%; height: 100%; max-height: 42px;}

.footer-bottom .footer-left { display:flex; flex-wrap:wrap; }
.footer-box { flex-basis: 40%; margin-bottom:30px; }
.footer-box:nth-child(1), .footer-box:nth-child(3) { flex-basis: 60%; padding-right: 5%;}
.footer-box:nth-child(3) { display: flex; flex-direction: column; justify-content: flex-end;}
.footer-box p { font-size:16px; line-height:130%; margin-bottom:10px; }
.footer-box p.mini { font-size:14px; margin-bottom: 5px;}
.footer-left .web-logo .footer_logo { display:block; margin-bottom: 5px; width: 100%; max-width: 160px; }

ul.contact li { margin-bottom:15px; }
ul.contact li a { display:flex; align-items: flex-start; color:$color-txt; font-size:16px; line-height:130%;}
ul.contact li a:hover { text-decoration: underline; }
ul.contact li a img { margin-right:10px; height:20px; width:20px; }
ul.contact li a span { display:inline-block; }

ul.footer_social li { margin-bottom:3px; }
ul.footer_social li a { font-size: 16px; line-height:130%; color:$color-txt; }
ul.footer_social li:last-child a { color:$color;}
ul.footer_social li a:hover { text-decoration: underline; }

.footer-map { position:relative; flex-basis:48%; background:white url(../images/map.jpg) no-repeat; background-size: cover; background-position: center; }
.footer-map a {  display: block; width: 100%; height: 100%;}
.footer-map a .map-icon {position: absolute; top: 50%; left: 50%; display: flex; width:40px; height:40px; margin-left: -20px; margin-top: -20px;}
.footer-map a .map-icon .icon-shadow { position: absolute; width: 52px; height: 52px; top: -6px; left: -6px; border-radius: 100px; background-color: rgba(10,12,120,.3); animation: blinkingshadow 4s ease-in-out infinite; -webkit-animation: blinkingshadow 4s ease-in-out infinite; transform: scale(1); opacity: 1;}
.footer-map a .map-icon .icon { width:40px; height:40px; background-color:#0a0c80; border-radius:100px; z-index: 1;}

.footer-mobile { padding: 20px 5%; }
.footer-mobile .web-logo { display: inline-block; margin: 20px 0 5px;}
.footer-mobile .web-logo .footer_logo { max-width: 130px;}
.footer-mobile p.mini { font-size: 12px;}

.hide-on-desktop { display:none; }
.hide-on-mobile { display:block; }


@media only screen and (max-width: 1250px){
	/*ul.logos-container li img { max-height: 35px;}*/
}
@media only screen and (max-width: 1100px){
	
}
@media only screen and (max-width: 1000px){
	.footer-top, .footer-bottom { flex-direction:column;}
	.footer-left { padding: 0 5%;}
	.footer-right { padding: 0 5%; margin-top:20px; }
	.footer-map { min-height: 300px;}
	ul.logos-container li img { max-height: 35px;}
}
@media only screen and (max-width: 600px){
	.footer-top, .footer-bottom { padding-top:30px;}
	.footer h3,
	.footer-box p,
	ul.contact li a,
	ul.footer_social li a { font-size:15px;}
	.footer-map { min-height: 200px;}
	.hide-on-desktop { display:block; }
	.hide-on-mobile { display:none; }
}
@media only screen and (max-width: 480px){
	.footer-bottom .footer-left { flex-direction: column;}
	.footer-box { margin-bottom:20px;}
	.footer-box:nth-child(1) { padding-right:0;} 
	.footer-box:nth-child(3) { display:none;}
	ul.footer_social { display:flex; flex-wrap:wrap; }
	ul.footer_social li { margin:0 10px 2px 0;}
}


