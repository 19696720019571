/* ------------------------------------------------------------------------- */


/* GENEREAL STYLE - Classes used throught all the project */


/* ------------------------------------------------------------------------- */


body {
    background-color: white;
    min-height: 100%;
    font-family:$main;
    color:$color-txt;
    font-size:18px;
    line-height:$line-height;
    -webkit-font-smoothing: antialiased;

}

p {
    font-size: 18px;
    line-height:$line-height;
    margin-bottom:20px;
}


.container {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding-left:5%;
    padding-right:5%;
}
.container-medio {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding-left:5%;
    padding-right:5%;
}
.container-mini {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding-left:5%;
    padding-right:5%;
}
.container-micro {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding-left:5%;
    padding-right:5%;
}

img {
    max-width: 100%;
    height: auto;
}

a,
button {
    cursor: pointer;
}
.clearfix, .clear{clear:both;}

strong{font-weight:$font-weight-bold;}

p a, a {text-decoration:none; color:$color; font-weight:$font-weight-normal;}

p i, i {font-style:italic; }
ul {list-style: none;}

sup {
    vertical-align: super;
    font-size: smaller;
}
hr { border:none; margin:25px 0; width:50px; height: 1px; background:$color; }
hr.center { margin:25px auto; }

.no_padding{padding: 0px !important;}
.align-center {text-align:center;}
.align-right {text-align:right;}
.padding-small{padding-top:40px; padding-bottom:40px;}
.padding-medium{padding-top:80px; padding-bottom:80px;}
.padding-big{padding-top:140px; padding-bottom:140px;}
.padding-anchor{padding-top:100px; padding-bottom:40px;}
.left {float:left;}
.right {float:right;}

/* CALLTOACTIONS */

a.calltoaction {display:inline-block; margin:0; width:auto; text-align:center; padding:10px 20px; color:white; font-family:$main; font-weight:$font-weight-normal; line-height:$line-height2; border-radius:$border-radius; text-transform:uppercase; font-size:14px;  background-color:$color; -webkit-transition: all .2s; -moz-transition: all .2s; -ms-transition: all .2s; -o-transition: all .2s; transition: all .2s; opacity:1; -webkit-appearance:none;}
a.calltoaction:hover { text-decoration:none; background-color:#000000;}

a.calltoaction.white {background:white; color:$color-txt; }
a.calltoaction.white:hover { text-decoration:none;  }

/* ANIMATIONS */

.animated { -webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-duration: 1.6s; -moz-animation-duration: 1.6s; -ms-animation-duration: 1.6s; -o-animation-duration: 1.6s; animation-duration: 1.6s; }
.fadeInUp { -webkit-animation-name: fadeInUp; -moz-animation-name: fadeInUp; -o-animation-name: fadeInUp; animation-name: fadeInUp;}
.fadeToLeft { -webkit-animation-name: fadeToLeft; -moz-animation-name: fadeToLeft; -o-animation-name: fadeToLeft; animation-name: fadeToLeft;}
.fadeToRight { -webkit-animation-name: fadeToRight; -moz-animation-name: fadeToRight; -o-animation-name: fadeToRight; animation-name: fadeToRight;}
.fadeOpacity { -webkit-animation-name: fadeOpacity; -moz-animation-name: fadeOpacity; -o-animation-name: fadeOpacity; animation-name: fadeOpacity;}
.fadeOut { -webkit-animation-name: fadeOut; -moz-animation-name: fadeOut; -o-animation-name: fadeOut; animation-name: fadeOut;}


@keyframes blinkingimage {
  50% { -webkit-transform: scaleY(1.0); -ms-transform: scaleY(1.0); transform: scaleY(1.0); }
}
@keyframes blinking {
    50% {opacity: 1;}
}
@keyframes blinkingshadow {
  50% { -webkit-transform: scale(2); -ms-transform: scale(2); transform: scale(2); opacity:0;}
}
@keyframes fadeInUp {
  0% { opacity: 0; -webkit-transform: translate(0, 200px); transform: translate(0, 200px); }
  100% {  opacity: 1; -webkit-transform: translate(0, 0); transform: translate(0, 0);  }
}
@keyframes fadeToLeft {
  0% { opacity: 0; -webkit-transform: translate(10px, 0); transform: translate(100px, 0); }
  100% {  opacity: 1; -webkit-transform: translate(0, 0); transform: translate(0, 0);  }
}
@keyframes fadeToRight {
  0% { opacity: 0; -webkit-transform: translate(-10px, 0); transform: translate(-100px, 0); }
  100% {  opacity: 1; -webkit-transform: translate(0, 0); transform: translate(0, 0);  }
}
@keyframes fadeOpacity {
  0% { opacity: 0;  }
  100% {  opacity: 1;  }
}
@keyframes fadeOut {
  0% { opacity: 1;  }
  50% {  opacity: 0;  }
}

@media only screen and (max-width: 1200px){
    body, p, ul li { font-size: 16px;}
}
@media only screen and (max-width: 800px){
    body, p, ul li { font-size: 15px;}

    .padding-small{padding-top:30px; padding-bottom:30px;}
    .padding-medium{padding-top:50px; padding-bottom:50px;}
    .padding-big{padding-top:80px; padding-bottom:80px;}
    .padding-anchor{padding-top:60px; padding-bottom:40px;}

    .columna2, .columna2-last{float:none; width:100%; margin-left:0; margin-right:0;}
    .columna4{width:49%; margin-bottom:30px;}
}
@media only screen and (max-width: 600px){
    p, ul li { font-size: 14px;}
    .padding-small{padding-top:25px; padding-bottom:25px;}
    .padding-medium{padding-top:30px; padding-bottom:30px;}
    .padding-big{padding-top:40px; padding-bottom:40px;}
    .padding-anchor{padding-top:40px; padding-bottom:40px;}

}
@media only screen and (max-width: 400px){

}

