/* ------------------------------------------------------------------------- */


/* GENERAL FORM STYLES */


/* ------------------------------------------------------------------------- */

.hundreds-form__error{
    display: none;
    color: #ff3b3b;
}

.hundreds-validation__input_error{
    border-bottom: 1px solid #ff3b3b !important;
}

label.hundreds-validation__input_error{
    border-bottom: 0px solid #ff3b3b !important;
    color: #ff3b3b !important;
}

.hundreds__messages--errors{
    color: #ff3b3b;
}

.hundreds-form__form{
    position: relative;
}
.hundreds-form__form-box-container { display: flex; justify-content: space-between;  align-items: flex-start;}
.submit-button-container {position:relative;  }
.hundreds__loader{ position: absolute; top: 0px; width: 100%; height: 100%; background-color: rgba(255,255,255,0.85); display: flex; align-items: center; justify-content: center; }

.contact-container-twocolumns { display: flex; align-items: flex-start; justify-content: space-between;}
.contact-container-twocolumns .contact-column { flex-basis:49%; }
.contact-box { margin-bottom:25px; }
.contact-box label { font-weight:$font-weight-bold; margin-bottom: 10px; display: inline-block; font-size: 16px; line-height:$line-height2;}

#inputs-cv { display:none; }

.hundreds__messages--hide, .hundreds__loader_hidden {display:none;}
.hundreds__messages--show {display:block;}
.hundreds__messages { margin: 30px 0 0; }
.hundreds__messages p { margin: 0; font-size: 16px; font-weight:$font-weight-bold; text-align: center; }

select { padding: 17px;  background: #f5f5f5 url(../images/arrow-select.svg) calc(100% - 10px) 50% no-repeat; font-size: 14px; font-family: $main; font-weight:$font-weight-normal; line-height:$line-height; border-radius:$border-radius; color:$color-txt; width:100%; border:none; box-sizing: border-box; outline: 0; -webkit-appearance: none;}

.checks-container {  }
/* checkbox labels privacy policy*/
.hundreds-form__checkbox input[type=checkbox] { display: none;}
.hundreds-form__checkbox {}
.hundreds-form__checkbox label { color: $color-txt;  cursor: pointer;  display: inline-block; font-size: 14px; font-weight:$font-weight-normal; line-height: $line-height2; vertical-align: middle; padding-left: 30px; margin-bottom:15px; position: relative; text-align: left;}
.hundreds-form__checkbox label:before { content: ''; display: block; position: absolute; top: 0px; left:0; height: 15px; width: 15px; border: 1px solid $color; background-color:transparent; border-radius:0px; }
.hundreds-form__checkbox label:after { content: ''; display: block; position: absolute; top: 3px; left:3px; height: 11px; width: 11px; background-color:white; border-radius:0px; transition: all .5s cubic-bezier(.15,.9,.34,.95);}
.hundreds-form__checkbox label a { color:$color; border-bottom: 1px solid $color-txt;  }
.hundreds-form__checkbox input[type=checkbox]:checked + label {color:$color-txt;} 
.hundreds-form__checkbox input[type=checkbox]:checked + label:after {background-color:$color;}

.radiobutton-container { display: flex; flex-wrap: wrap;}

.hundreds-form__radio input[type=radio] { display: none;}
.hundreds-form__radio {}
.hundreds-form__radio label { color: $color-txt;  cursor: pointer;  display: block; font-size: 14px; font-weight:$font-weight-normal; line-height: $line-height2; vertical-align: middle; padding-left: 25px; margin:0 25px 15px 0; position: relative; text-align: left;}
.hundreds-form__radio label:before { content: ''; display: block; position: absolute; top: 0px; left:0; height: 15px; width: 15px; border: 1px solid $color; background-color:transparent; border-radius:50px; }
.hundreds-form__radio label:after { content: ''; display: block; position: absolute; top: 3px; left:3px; height: 11px; width: 11px; background-color:white; border-radius:50px; transition: all .5s cubic-bezier(.15,.9,.34,.95);}
.hundreds-form__radio label a { color:$color; border-bottom: 1px solid $color-txt;  }
.hundreds-form__radio input[type=radio]:checked + label {color:$color-txt;} 
.hundreds-form__radio input[type=radio]:checked + label:after {background-color:$color;}

form input[type=email], form input[type=text], form input[type=number], form textarea {
    border:none;
    border-bottom: 1px solid $color-txt;
    padding: 5px;
    border-radius:$border-radius;
    color:$color-txt;
    font-family: $main;
    font-weight:$font-weight-semibold;
    font-size: 18px;
    background: transparent;
    outline: 0;
    width: 100%;
    transition: all .5s cubic-bezier(.15,.9,.34,.95);
    -webkit-appearance: none;
}

form textarea {  resize:none;}



form input[type=submit] {
    display:inline-block;
    position:relative;
    background:$color;
    font-size:18px;
    font-family:$main;
    font-weight:$font-weight-semibold;
    cursor: pointer;
    border-radius:$border-radius;
    border:none;
    color: white;
    border:1px solid transparent;
    opacity:1;
    margin:0;
    outline: 0;
    padding: 10px 25px;
    text-transform:uppercase;
    -webkit-appearance: none;
    -moz-transition: all .2s cubic-bezier(.15,.9,.34,.95);  -o-transition: all .2s cubic-bezier(.15,.9,.34,.95); -webkit-transition: all .2s cubic-bezier(.15,.9,.34,.95); transition: all .2s cubic-bezier(.15,.9,.34,.95);
}
form input[type=submit]:hover {
    background:$color-txt;
}

::-webkit-input-placeholder {  opacity:0.8;}
:-moz-placeholder {   opacity:0.8;}
::-moz-placeholder {  opacity:0.8; }
:-ms-input-placeholder {   opacity:0.8;}


/* SPECIFIC SEARCH FORM */
.header-news-search input[type=search] { position:relative; width:100%; background:#ffffff url(../images/search.svg) no-repeat; background-position:95% center; border-radius:$border-radius; padding:8px 40px 8px 10px; border: none; border-bottom:1px solid $color; margin:0; line-height:$line-height2; }
/*.members-search form { position:relative; }
.members-search form input[type=text] { width:100%; padding: 10px 40px 10px 15px;}
.members-search form input[type=submit] { position:absolute; top:0; right:0; width:30px; height:100%; background:transparent url(../images/search.svg) no-repeat center; border: none; margin:0; }
*/

/* INPUT FILE */

input[type=file]{ position: relative; font-family:$main; font-size:14px; height: 50px; border: none; border-radius:$border-radius; background-color:#f5f5f5; color: $color-txt; line-height: 50px; width:100%; padding: 0 25px;}
input[type=file]::-webkit-file-upload-button { visibility: hidden;}
input[type=file]::before { content: 'Seleccionar arxiu'; position:absolute; left: 5px; display: inline-block; background-color: transparent; border-radius:$border-radius; padding: 0 10px; outline: none; color: $color; margin-right: 10px; white-space: nowrap; -webkit-user-select: none; text-transform: uppercase; font-weight:$font-weight-normal; cursor: pointer; font-size: 14px; opacity:1;}
p.curriculum-error-label { margin: 2px 0; font-size: 13px; color: #ff3b3b!important; }
.no_visible { display: none;}


input[type=text].bots-input { position: absolute; height: 0; font-size: 1px; line-height: 0%; padding: 0; margin: 0; opacity: 0; visibility: hidden;}


@media only screen and (max-width: 800px){
    form input[type=email], form input[type=text], form input[type=number], form textarea { padding:10px; }
    select { padding: 12px;}

    form label { font-size: 14px;}
    .hundreds-form__checkbox {margin: 0; width: auto;}
}
@media only screen and (max-width: 600px){ 
    .contact-container-twocolumns { flex-direction: column;}
    .contact-container-twocolumns .contact-column { width: 100%;}
    .contact-box { margin-bottom: 15px;}
    .contact-box label { margin-bottom: 5px; font-size: 14px;}
    .hundreds-form__radio label { margin:0 25px 15px 0; }
    .radiobutton-container { margin-top: 15px;}
}
@media only screen and (max-width: 480px){
    .checks-container { text-align: left;}
    form input[type=submit]{ width:100%;}
}